import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/providers/navigation.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {
  constructor(private navigation: NavigationService) {}

  ngOnInit() {}

  back() {
    this.navigation.back();
  }
}
