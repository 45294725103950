import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/providers/authentication.service";

@Component({
  selector: "app-guest-event-toy",
  templateUrl: "./guest-event-toy.component.html",
  styleUrls: ["./guest-event-toy.component.scss"],
})
export class GuestEventToyComponent implements OnInit {
  constructor(private router: Router,
    private authService: AuthenticationService,
  ) {}

  async ngOnInit() {
    await this.authService.isAuthenticated();
  }

  gotoToyContribute() {
    this.router.navigate(["/guest/1234214/toy/contribute"]);
  }

  back() {
    this.router.navigate(["/guest/1234124143/rsvp/yesconfirmed"]);
  }
}
