import { NgModule } from "@angular/core";
import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
import { setContext } from "apollo-link-context";
import { HttpLinkModule, HttpLink } from "apollo-angular-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpHeaders } from "@angular/common/http";
import { AuthenticationService } from "./providers/authentication.service";

// const uri = 'http://localhost:5000/toy-mint/us-central1/api/graphql'; // <-- add the URL of the GraphQL server here
const uri = "https://us-central1-toy-mint.cloudfunctions.net/api/graphql"; // <-- add the URL of the GraphQL server here
const auth = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  // return the headers to the context so httpLink can read them
  // in this example we assume headers property exists
  // and it is an instance of HttpHeaders
  if (!authUser || !authUser["stsTokenManager"]) {
    return {};
  } else {
    if (!headers) {
      headers = new HttpHeaders();
    }
    return {
      headers: headers.append(
        "Authorization",
        `Bearer ${authUser["stsTokenManager"]["accessToken"]}`
      ),
    };
  }
});

export function createApollo(httpLink: HttpLink) {
  return {
    link: auth.concat(httpLink.create({ uri })),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
