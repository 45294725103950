import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { Location } from '@angular/common';
import { PoolEvent } from 'src/app/models/event';
import { EventsAPI } from 'src/app/providers/events.api.service';

@Component({
  selector: 'app-guest-event-birthday-card',
  templateUrl: './guest-event-birthday-card.component.html',
  styleUrls: ['./guest-event-birthday-card.component.scss'],
})
export class GuestEventBirthdayCardComponent implements OnInit {
  eventId: string = '';
  event: PoolEvent;
  public btnText: string = 'Sign the Card';
  public signed: boolean = false;
  public childName: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private _location: Location,
    private eventService: EventsAPI
  ) {}

  async ngOnInit() {
    await this.authService.isAuthenticated();
    const event_id_partial = window.location.href.split('/guest/')[1];
    this.eventId = event_id_partial.split('/')[0];

    this.route.params.subscribe(async (params) => {
      this.eventId = params['eventId'];
      console.log(this.eventId);
      const eventResponse = await this.eventService.getEventByShortCode(this.eventId).toPromise();
      const loadedEvent = eventResponse as PoolEvent;
      this.event = loadedEvent;
      this.childName = loadedEvent.kidsName;
    });

    // Check to see if card has been signed
    if (this.signed) {
      this.btnText = 'Edit Message';
    }
  }

  gotoBirthdayCard() {
    this.router.navigate([`/guest/${this.eventId}/birthdaycard/sign`]);
  }

  gotoEvent() {
    this.router.navigate([`/guest/${this.eventId}`]);
  }

  back() {
    this._location.back();
  }
}
