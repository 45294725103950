import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthAPI } from 'src/app/providers/authentication.api.service';
import { EventsAPI } from 'src/app/providers/events.api.service';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss'],
})
export class EventListComponent implements OnInit {
  public events;

  constructor(private router: Router, private authAPI: AuthAPI, public eventsService: EventsAPI) {}

  async ngOnInit() {
    const token = this.authAPI.acc_token;
    if (!token) {
      this.router.navigate(['/'], { queryParams: { user: 'not_found' } });
    } else {
      this.events = await this.eventsService.getEvents(token);
      console.log(this.events);
    }
  }

  gotoCreateInvite() {
    this.router.navigate(['/host/create-1']);
  }

  gotoEvent(key: string) {
    this.router.navigate([`/host/${key}`]);
  }

  goHome() {
    this.router.navigate(['/']);
  }

  logout() {
    this.authAPI.deleteAccountToken();
    this.router.navigate(['/']);
  }

  public isCollapsed1 = false;
  public isCollapsed2 = false;
}
