import * as moment from 'moment';

export function getDate(iso_str: string) {
  const date_obj = new Date(iso_str);
  // console.log(date_obj);
  const formatted_date = moment(date_obj).format('MMM D, YYYY');
  // console.log('partyDate: ' + formatted_date);
  return formatted_date;
}

export function getTime(iso_str: string) {
  if (iso_str) {
    const date_obj = new Date(iso_str);
    const formatted_time = moment(date_obj).format('h:mm A');
    return formatted_time;
  } else return null;
}

export function getDateSuffix(num: number) {
  num = Number(num);
  if (num % 10 == 1) {
    return 'st';
  } else if (num % 10 == 2) {
    return 'nd';
  } else if (num % 10 == 3) {
    return 'rd';
  } else return 'th';
}

export function formatHours(num: number) {
  // number = 24 hour time
  // return am/pm time
  if (num > 12) {
    return num - 12;
  } else return num;
}

export function getAMPM(num: number) {
  // number = 24 hour time
  // return 'AM' or 'PM'
  if (num > 12) {
    return 'PM';
  } else return 'AM';
}

export function getTimePartials(time: string) {
  // time = '7:00 PM' format
  const time_split = time.split(' ')[0]; // 7:00
  const ampm = time.split(' ')[1]; // PM
  let hours = Number(time_split.split(':')[0]); // 7
  if (hours != 12 && ampm === 'PM') {
    hours = hours + 12; // 19
  }
  const mins = Number(time_split.split(':')[1]); // 00

  return [hours, mins, ampm];
}
