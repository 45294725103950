// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBEDMod18_l4wPf-mCUBVmHTMGPH02UrvE',
    clientId: '931595183762-ju4ub20kk8cjl66n7me8al8vosn3ljpd.apps.googleusercontent.com',
    authDomain: 'toy-mint.firebaseapp.com',
    databaseURL: 'https://toy-mint.firebaseio.com',
    projectId: 'toy-mint',
    storageBucket: 'toy-mint.appspot.com',
    messagingSenderId: '931595183762',
  },
  // REST_API_SERVER: 'http://toymint_api:3003',
  REST_API_SERVER: 'https://dev-api.birthdayteddy.com',
  CLIENT_ID: 'fa3fbb8b22544a7fb9a693cff370141e',
  PAYPAL_CLIENT_SECRET: '',
  APP_DOMAIN: 'test.teddy.party',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
