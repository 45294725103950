import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-collapsable-section',
  templateUrl: './collapsable-section.component.html',
  styleUrls: ['./collapsable-section.component.scss'],
})
export class CollapsableSectionComponent implements OnInit {
  @Input() sectionCollapsed: boolean = false;
  @Input() heading: string = '';
  @Input() headingClass: string = '';
  @Input() sectionClass: string = '';

  constructor() {}

  ngOnInit() {}
}
