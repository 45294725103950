import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "src/app/providers/authentication.service";
import { first, take } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class LoginResolver implements Resolve<boolean> {
  constructor(private service: AuthenticationService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {
    return new Promise(async (resolve,reject) => {
      this.service.fbAuthState().subscribe((user)=>{
        console.log("resolving loggin with user from localstorage");
        resolve(user);
      });
      
      await this.service.isAuthenticated();
      console.log("isAuthenticated returned");
      
    });
  }
}