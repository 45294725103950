import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventsAPI } from 'src/app/providers/events.api.service';
import { AuthAPI } from 'src/app/providers/authentication.api.service';
import { PoolEvent } from 'src/app/models/event';

@Component({
  selector: 'app-guest-giftpool-confirmed',
  templateUrl: './guest-giftpool-confirmed.component.html',
  styleUrls: ['./guest-giftpool-confirmed.component.scss'],
})
export class GuestGiftpoolConfirmedComponent implements OnInit {
  private token: string;
  isLoading: boolean = true;
  eventKey: string;
  event: PoolEvent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authAPI: AuthAPI,
    public eventService: EventsAPI
  ) {}

  async ngOnInit() {
    // Get token
    this.token = this.authAPI.anon_token;
    if (!this.token) {
      this.token = await this.authAPI.getAnonToken();
    }

    this.route.params.subscribe(async (params) => {
      await this.routeLoaded(params);
    });
  }

  async routeLoaded(params) {
    this.eventKey = params['eventId'];
    const eventResponse = await this.eventService.getEvent(this.eventKey, this.token).toPromise();
    this.event = eventResponse as PoolEvent;
    this.isLoading = false;
  }

  gotoEvent() {
    this.router.navigate([`/guest/${this.eventKey}`]);
  }

  gotoEventBirthdayCard() {
    this.router.navigate([`/guest/${this.eventKey}/birthdaycard`]);
  }
}
