import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getDate, getTime } from 'src/app/shared/functions';

import { PoolEvent, GiftPoolEvent } from '../../../models/event';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { AuthAPI } from 'src/app/providers/authentication.api.service';
import { EventsAPI } from 'src/app/providers/events.api.service';

@Component({
  selector: 'app-guest-event-rsvp-yes-confirmed',
  templateUrl: './guest-event-rsvp-yes-confirmed.component.html',
  styleUrls: ['./guest-event-rsvp-yes-confirmed.component.scss'],
})
export class GuestEventRsvpYesConfirmedComponent implements OnInit {
  eventId: string;
  invitationCode: string;
  event: PoolEvent;
  title: string;
  startTime: string;
  endTime: string;
  partyDate: string;
  description: string;
  location: string;
  private token: string;
  giftpool: GiftPoolEvent;
  dataLoaded: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authAPI: AuthAPI,
    private eventService: EventsAPI,
    private modalService: NgbModal,
    private authService: AuthenticationService
  ) {}
  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  async ngOnInit() {
    // Get token
    this.token = this.authAPI.anon_token;
    if (!this.token) {
      this.token = await this.authAPI.getAnonToken();
    }

    await this.authService.isAuthenticated();
    this.route.params.subscribe(async (params) => {
      this.eventId = params['eventId'];
      const eventResponse = await this.eventService.getEventByShortCode(this.eventId).toPromise();
      const loadedEvent = eventResponse as PoolEvent;
      this.event = loadedEvent;
      this.title = this.event.title;
      this.partyDate = this.event.startsAt.substring(0, 10);
      this.startTime = getTime(this.event.startsAt);
      this.endTime = getTime(this.event.endsAt);
      this.description = this.event.description;
      this.location = this.event.location;

      // Check if gift pool exists
      try {
        const poolResponse = await this.eventService.getGiftPool(this.eventId, this.token).toPromise();
        this.giftpool = poolResponse as GiftPoolEvent;
        console.log('---------- gift pool');
        console.log(this.giftpool);
      } catch {}

      //reinitialize addToCalendar button
      setTimeout(function () {
        (window as any).addeventatc.refresh();
      }, 100);

      console.log(this.event);
      this.dataLoaded = true;
    });
  }

  gotoEventToy() {
    this.router.navigate(['/guest/123412412/toy']);
  }

  gotoEventBirthdayCard() {
    this.router.navigate([`/guest/${this.eventId}/birthdaycard`]);
  }

  gotoGiftPool() {
    this.router.navigate([`/guest/${this.eventId}/giftpool/contribute`]);
  }

  gotoChangeRSVP() {
    this.router.navigate(['/guest/12341241243/rsvp/change']);
  }

  gotoEvent() {
    this.router.navigate([`/guest/${this.eventId}`]);
  }

  openModal(content) {
    this.modalService.open(content);
  }

  addToCalendar() {
    window.open(
      `data:text/calendar;charset=utf8,BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
URL:https://invitation.toymint.co/guest/${this.eventId}
DTSTART:${this.event.startsAt}
DTEND:${this.event.endsAt}
SUMMARY:${this.event.title}
DESCRIPTION:${this.event.description}
END:VEVENT
END:VCALENDAR`,
      '_blank'
    );
  }
}
