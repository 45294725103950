import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { PaypalClient } from 'src/app/models/payments';

@Injectable({
  providedIn: 'root',
})
export class PaymentsAPI {
  private REST_API_SERVER = environment.REST_API_SERVER;
  constructor(private httpClient: HttpClient) {}

  getPPClient(token: string) {
    const headers = { Authorization: `Bearer ${token}` };
    return this.httpClient.get(`${this.REST_API_SERVER}/api/paypal/client`, { headers }).pipe(map((resp) => resp));
  }

  async createOrder(amount: number) {
    const baseUrl = {
      sandbox: 'https://api-m.sandbox.paypal.com',
      live: 'https://api-m.paypal.com',
    };

    async function generateAccessToken() {
      try {
        const client = this.getPPClient() as PaypalClient;
        const client_id = client.id;
        const auth = Buffer.from(`${client_id}:${environment.PAYPAL_CLIENT_SECRET}`).toString('base64');

        const response = await fetch(`${baseUrl.sandbox}/v1/oauth2/token`, {
          method: 'POST',
          body: 'grant_type=client_credentials',
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        });

        const data = await response.json();
        return data.access_token;
      } catch (error) {
        console.error('Failed to generate Access Token:', error);
      }
    }

    // create an order
    const accessToken = await generateAccessToken();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    };
    const payload = JSON.stringify({
      intent: 'CAPTURE',
      purchase_units: [
        {
          amount: {
            currency_code: 'CAD',
            value: amount,
          },
        },
      ],
      payment_source: {
        paypal: {},
      },
    });

    return this.httpClient
      .post(`${baseUrl.sandbox}/v2/checkout/orders`, payload, { headers })
      .pipe(map((resp) => resp));
  }
}
