import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToypoolCarouselComponent } from '../../../components/toypool-carousel/toypool-carousel.component';
import { NgbModal, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { AuthAPI } from 'src/app/providers/authentication.api.service';
import { EventsAPI } from 'src/app/providers/events.api.service';
import { PoolEvent } from 'src/app/models/event';

@Component({
  selector: 'app-host-create-toypool-choose',
  templateUrl: './host-create-toypool-choose.component.html',
  styleUrls: ['./host-create-toypool-choose.component.scss'],
})
export class HostCreateToypoolChooseComponent implements OnInit {
  model: NgbDateStruct;
  date: { year: number; month: number };
  eventId: string;
  event: PoolEvent;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private calendar: NgbCalendar,
    private route: ActivatedRoute,
    private authAPI: AuthAPI,
    private eventService: EventsAPI
  ) {}

  async ngOnInit() {
    const token = await this.authAPI.getAnonToken();
    this.route.params.subscribe(async (params) => {
      this.eventId = params['eventId'];
      const eventResponse = await this.eventService.getEvent(this.eventId, token).toPromise();
      this.event = (eventResponse as any).event;
      console.log(this.event);
    });
  }

  gotoToypoolTerms() {
    this.router.navigate([`/host/${this.eventId}/toypool/terms`]);
  }

  gotoToypoolGoodieBoxes() {
    this.router.navigate([`/host/${this.eventId}/goodieboxes`]);
  }

  back() {
    this.router.navigate([`/host/${this.eventId}/toypool`]);
  }

  openHowModal(content) {
    this.modalService.open(content);
  }

  openChangeDateModal(content) {
    this.modalService.open(content);
  }

  gotoToypoolPreview() {
    this.router.navigate([`/host/${this.eventId}`]);
  }
}
