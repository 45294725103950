import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PoolEvent } from 'src/app/models/event';
import * as moment from 'moment';
import { AuthAPI } from 'src/app/providers/authentication.api.service';
import { EventsAPI } from 'src/app/providers/events.api.service';
import { getDate, getTime } from 'src/app/shared/functions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.scss'],
})
export class EmailTemplateComponent implements OnInit {
  eventId: string;
  loadedEvent: any;
  eventDate: string;
  startTime: string;
  endTime: string;
  appDomain: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authAPI: AuthAPI,
    private eventService: EventsAPI
  ) {}

  async ngOnInit() {
    const token = await this.authAPI.getAnonToken();
    this.route.params.subscribe(async (params) => {
      console.log(params);
      this.eventId = params['eventId'];
      const eventResponse = await this.eventService.getEvent(this.eventId, token).toPromise();
      this.loadedEvent = eventResponse as PoolEvent;
      this.appDomain = environment.APP_DOMAIN;
      console.log(this.loadedEvent);

      this.eventDate = getDate(this.loadedEvent.startsAt);
      this.startTime = getTime(this.loadedEvent.startsAt);
      this.endTime = getTime(this.loadedEvent.endsAt);
    });
  }
}
