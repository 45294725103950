import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-bday-video-carousel',
  templateUrl: './bday-video-carousel.component.html',
  styleUrls: ['./bday-video-carousel.component.scss'],
})
export class BdayVideoCarouselComponent implements OnInit {
  @Input() themes: any[];
  @Input() selectedThemeId: string;
  @Input() isEventHost: boolean = true;
  @Output() updateTheme = new EventEmitter<string>();
  selectingVideo: boolean = false;
  currentThemeId: string;
  currentIndex: number;
  isLoading: boolean = true;

  constructor() {}

  ngOnInit() {
    // console.log(this.themes);
    if (this.themes && this.selectedThemeId) {
      this.determineSelectedTheme();
    }
    this.isLoading = false;
  }

  determineSelectedTheme() {
    this.themes.forEach(([id, url], i) => {
      if (id === this.selectedThemeId) {
        this.currentIndex = i;
        this.currentThemeId = this.themes[i][0];
      }
    });
  }

  toggleChangeVideo() {
    if (this.selectingVideo) {
      this.determineSelectedTheme();
    }
    this.selectingVideo = !this.selectingVideo;
  }

  viewVideo(str: string) {
    const last_index = this.themes.length - 1;
    var this_index = this.currentIndex;

    if (str === 'prev') {
      if (this_index === 0) {
        this_index = last_index;
      } else {
        this_index = this.currentIndex - 1;
      }
    }

    if (str === 'next') {
      if (this_index === last_index) {
        this_index = 0;
      } else {
        this_index = this.currentIndex + 1;
      }
    }

    this.currentIndex = this_index;
    this.currentThemeId = this.themes[this_index][0];
  }

  saveSelection() {
    this.updateTheme.emit(this.currentThemeId);
    this.selectingVideo = !this.selectingVideo;
  }
}
