import { Component, NgZone, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventsAPI } from 'src/app/providers/events.api.service';
import { Location } from '@angular/common';
import { AuthAPI } from 'src/app/providers/authentication.api.service';
import { PaymentsAPI } from 'src/app/providers/payments.api.service';
import { PoolEvent } from 'src/app/models/event';
import { Principal, Account } from 'src/app/models/account';
import { PaypalClient } from 'src/app/models/payments';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-guest-giftpool-contribute',
  templateUrl: './guest-giftpool-contribute.component.html',
  styleUrls: ['./guest-giftpool-contribute.component.scss'],
})
export class GuestGiftpoolContributeComponent implements OnInit {
  private token: string;
  private account: Account;
  private accountKey: string;
  private accountType: string;
  isLoading: boolean = true;
  eventKey: string;
  event: PoolEvent;
  giftPool: any;
  paymentUrl: string;
  myScriptElement: HTMLScriptElement;
  customAmount: number = 5;
  validation_messages;
  customGiftAmountForm;
  giftpoolForm;
  showCustomAmount: boolean = false;
  showPaymentOptions: boolean = false;
  giftAmount: number;
  contributorName: string;
  email: string;
  loadingPayment: boolean = true;
  fieldsLoaded: boolean = false;
  submittingPayment: boolean = false;
  PAYPAL_SDK_URL = 'https://www.paypal.com/sdk/js';
  REST_API_SERVER = environment.REST_API_SERVER;
  PAYMENT_CURRENCY = 'CAD';
  PAYMENT_INTENT = 'capture';
  paypalStyles = {
    '.valid': {
      color: 'green',
    },
    '.invalid': {
      color: 'red',
    },
    input: {
      'font-size': '12pt',
      color: '#ffffff',
    },
  };

  paypalFields = {
    number: {
      selector: '#card-number-field-container',
      placeholder: '4111 1111 1111 1111',
    },
    cvv: {
      selector: '#card-cvv-field-container',
      placeholder: '123',
    },
    expirationDate: {
      selector: '#card-expiry-field-container',
      placeholder: 'MM/YY',
    },
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authAPI: AuthAPI,
    public eventService: EventsAPI,
    private paymentsService: PaymentsAPI,
    private _location: Location,
    private httpClient: HttpClient,
    private ngZone: NgZone
  ) {
    this.customGiftAmountForm = new FormGroup({
      amount: new FormControl(this.customAmount, {
        validators: Validators.compose([
          Validators.required,
          Validators.min(5),
          Validators.pattern(/^\d{1,}\.?\d{0,2}$/),
        ]),
        updateOn: 'change',
      }),
    });
    this.giftpoolForm = new FormGroup({
      name: new FormControl(this.contributorName, {
        validators: Validators.compose([Validators.required]),
        updateOn: 'change',
      }),
      email: new FormControl(this.email, {
        validators: Validators.compose([
          Validators.required,
          Validators.pattern(
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i
          ),
        ]),
        updateOn: 'change',
      }),
    });

    this.validation_messages = {
      amount: [
        { type: 'required', message: 'Cannot be blank' },
        { type: 'min', message: 'Must be a minimum of $5' },
        { type: 'pattern', message: 'Must be a number' },
      ],
      name: [{ type: 'required', message: 'This field is required' }],
      email: [
        { type: 'required', message: 'This field is required' },
        { type: 'pattern', message: 'Please enter a valid email' },
      ],
    };
  }

  async ngOnInit() {
    // Populate email
    const user_email = sessionStorage.getItem('teddy.userEmail');
    if (user_email !== null) {
      this.email = user_email;
    }

    // Get token
    this.token = this.authAPI.anon_token;
    if (!this.token) {
      this.token = await this.authAPI.getAnonToken();
    }

    // Get account principal
    const principal = await this.authAPI.getAuthPrincipal(this.token);
    const loadedPrincipal = principal as Principal;
    this.accountType = loadedPrincipal.type;
    this.accountKey = loadedPrincipal.key;
    console.log(loadedPrincipal);

    // const account = await this.authAPI.getAccountInfo(await this.authAPI.getAnonToken(), this.accountKey);
    // console.log(account);

    await this.appendPaypalScripts();

    this.route.params.subscribe(async (params) => {
      await this.routeLoaded(params);
    });
  }

  async routeLoaded(params) {
    this.eventKey = params['eventId'];

    // Redirect if not user account
    // if (this.accountType !== 'account') {
    //   console.log(this.accountType);
    //   this.router.navigate([`/guest/${this.eventKey}`]);
    // }

    const eventResponse = await this.eventService.getEvent(this.eventKey, this.token).toPromise();
    this.event = eventResponse as PoolEvent;
    this.giftPool = await this.eventService.getGiftPool(this.eventKey, this.token).toPromise();
    this.isLoading = false;

    console.log(this.event);
  }

  back() {
    this._location.back();
  }

  toggleCustomAmountForm() {
    this.showCustomAmount = !this.showCustomAmount;
    this.giftAmount = this.customAmount;
  }

  async request(requestMethod, requestEndpoint, jsonBody) {
    const token = await this.authAPI.getAnonToken();
    const headers = { Authorization: `Bearer ${token}` };
    const defaultPaypalRequestHeader = { ...headers, 'Content-Type': 'application/json; charset=utf-8' };
    return fetch(requestEndpoint, {
      method: requestMethod,
      headers: defaultPaypalRequestHeader,
      body: requestMethod !== 'get' ? JSON.stringify(jsonBody) : undefined,
    });
  }

  async getPaypalClientId() {
    const response = await this.request('get', `${this.REST_API_SERVER}/api/paypal/client/id`, {});
    const clientId = await response.text();
    return clientId;
  }

  async getPaypalClientToken() {
    const response = await this.request('get', `${this.REST_API_SERVER}/api/paypal/client/token`, {});
    const clientToken = await response.text();
    return clientToken;
  }

  async getPayment(paymentId: string) {
    const response = await this.request('get', `${this.REST_API_SERVER}/api/payments/${paymentId}`, {});
    const payment = await response.json();
    console.log(payment);
    return payment;
  }

  async createPaypalOrder() {
    const orderAmount = this.giftAmount || 0;
    const response = await this.request('post', `${this.REST_API_SERVER}/api/pools/${this.giftPool.id}/contributions`, {
      value: orderAmount * 100,
      contributorName: this.contributorName,
      contributorEmail: this.email,
    });
    const responseBody = await response.json();
    console.log(responseBody);
    return responseBody.paymentId;
  }

  async completePaypalOrder(orderId) {
    const response = await this.request('post', `${this.REST_API_SERVER}/api/paypal/capture/${orderId}`, {});
    console.log(response.status);

    if (response.status !== 200) {
      console.info('FAILED_CREATING_ORDER', response.statusText);
    } else {
      console.info('SUCCESSFULY_COMPLETED_ORDER', response.statusText);
    }

    return;
  }

  async appendPaypalScripts() {
    const clientId = await this.getPaypalClientId();

    const clientToken = await this.getPaypalClientToken();

    const script = document.createElement('script');

    document.head.appendChild(script);

    const paypalUrl =
      this.PAYPAL_SDK_URL +
      '?client-id=' +
      clientId +
      '&currency=' +
      this.PAYMENT_CURRENCY +
      '&intent=' +
      this.PAYMENT_INTENT +
      '&enable-funding=' +
      'venmo' +
      '&components=' +
      'buttons,card-fields';

    console.log(paypalUrl);
    script.setAttribute('data-client-token', clientToken);
    script.setAttribute('src', paypalUrl);

    return new Promise<void>((resolve, reject) => {
      script.addEventListener('load', () => {
        resolve();
      });
      script.addEventListener('error', () => {
        console.info('FAILED_TO_APPEND_PAYPAL_SCRIPT');
        reject();
      });
    });
  }

  async togglePaymentOptions() {
    this.showPaymentOptions = true;
    if (!this.fieldsLoaded) {
      let orderId;
      this.fieldsLoaded = true;
      // wait for a second to allow the paypal script to load
      await new Promise((resolve) => setTimeout(resolve, 1));
      const cardField = await (window as any).paypal.CardFields({
        createOrder: async () => {
          this.submittingPayment = true;
          const paymentId = await this.createPaypalOrder();
          const payment = await this.getPayment(paymentId);
          return payment.providerData.paypalId;
        },
        onApprove: async (response) => {
          console.log(response);
          console.log('onApprove', response.orderID);
          orderId = response.orderID;
        },
        onError: (err) => {
          console.log(err);
          alert(err.message);
          this.submittingPayment = false;
        },
        style: this.paypalStyles,
      });

      const cardNumberContainer = document.getElementById('card-number-field-container');
      const cardCvvContainer = document.getElementById('card-cvv-field-container');
      const cardExpiryContainer = document.getElementById('card-expiry-field-container');
      const multiCardFieldButton = document.getElementById('card-field-submit-button');
      // Render each field after checking for eligibility
      if (cardField.isEligible()) {
        const numberField = cardField.NumberField();
        numberField.render(cardNumberContainer);
        const cvvField = cardField.CVVField();
        cvvField.render(cardCvvContainer);
        const expiryField = cardField.ExpiryField();
        expiryField.render(cardExpiryContainer);
        // Add click listener to the submit button and call the submit function on the CardField component
        multiCardFieldButton.addEventListener('click', () => {
          cardField
            .submit()
            .then(async () => {
              await this.completePaypalOrder(orderId);
              console.log('submit success');
              this.router.navigate([`/guest/${this.eventKey}/giftpool/thankyou`]);
            })
            .catch((err) => {
              console.log('submit fail');
              console.log(err);
              // Handle an unsuccessful payment
            });
        });
      }
      /*
      document.querySelector('#checkout-form').addEventListener('submit', async (e) => {
        e.preventDefault();
        await cardField.submit();
      });
      */
    }
    this.loadingPayment = false;
  }

  async giveAmount(num: number) {
    this.loadingPayment = true;
    this.showCustomAmount = false;
    this.giftAmount = num;
    await this.togglePaymentOptions();
  }

  async giveCustomAmount() {
    this.giftAmount = this.customAmount;
    await this.togglePaymentOptions();
  }
}
