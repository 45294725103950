import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-login-email",
  templateUrl: "./login-email.component.html",
  styleUrls: ["./login-email.component.scss"],
})
export class LoginEmailComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  back() {
    this.router.navigate(["/login"]);
  }

  gotoHostStart() {
    this.router.navigate(["/host/start"]);
  }

  // New user
  gotoLoginCreate() {
    this.router.navigate(["/login/create"]);
  }

  // Return user
  gotoPassword() {
    this.router.navigate(["/login/password"]);
  }
}
