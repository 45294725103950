import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeUrl',
})
export class SanitizeUrl implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}

  transform(url: string): SafeResourceUrl {
    // return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    return this.domSanitizer.sanitize(SecurityContext.RESOURCE_URL, url);
  }
}
