import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/providers/navigation.service';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
})
export class TermsOfUseComponent implements OnInit {
  constructor(private navigation: NavigationService) {}

  ngOnInit() {}

  back() {
    this.navigation.back();
  }
}
