import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AngularFireAuth } from "angularfire2/auth";
import * as firebase from "firebase";
import { User } from "firebase";

declare const gapi;

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  authenticationState = new BehaviorSubject(false);
  user: any = null;
  isAnonymous: Boolean = true;
  user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  idToken$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private afAuth: AngularFireAuth) {
    afAuth.user.subscribe(this.user$);
    afAuth.idToken.subscribe(this.idToken$);
  }

  public async googleSignIn() {
    const googleAuth = gapi.auth2.getAuthInstance();
    const googleUser = await googleAuth.signIn();

    const token = googleUser.getAuthResponse().id_token;

    const credential = firebase.auth.GoogleAuthProvider.credential(token);
    await firebase.auth().currentUser.linkWithCredential(credential);

    /*
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().currentUser.linkWithPopup(provider);
    */
  }

  async login(email, password) {
    const credentials = await this.afAuth.auth.signInWithEmailAndPassword(
      email,
      password
    );
    if (credentials.user.uid) {
      this.authenticationState.next(true);
    } else {
      this.authenticationState.next(false);
    }
  }

  async logout() {
    await this.afAuth.auth.signOut();
    this.authenticationState.next(false);
  }

  async register(email, password) {
    if (email && password) {
      const authData = await this.afAuth.auth.createUserWithEmailAndPassword(
        email,
        password
      );
      this.authenticationState.next(true);
      return authData;
    }
    return null;
  }

  async isAuthenticated() {
    this.user = await this.afAuth.auth.signInAnonymously();
    if (this.user) {
      this.authenticationState.next(true);
      return true;
    }
  }

  currentUser() {
    return JSON.parse(localStorage.getItem("authUser"));
  }

  fbAuthState(){
    return this.afAuth.authState;
  }
}
