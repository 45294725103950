import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PoolEvent } from '../../../models/event';
import * as moment from 'moment';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { EventsAPI } from 'src/app/providers/events.api.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-guest-event-rsvp-no',
  templateUrl: './guest-event-rsvp-no.component.html',
  styleUrls: ['./guest-event-rsvp-no.component.scss'],
})
export class GuestEventRsvpNoComponent implements OnInit {
  rsvpFormNo;
  validation_messages;
  eventId: string;
  invitationCode: string;
  event: PoolEvent;
  eventDate: string;
  startTime: string;
  endTime: string;
  parentEmail: string;
  parentName: string;
  kidNames: string;
  cardMessage: string = '';

  createItem() {
    return new FormGroup({
      kidName: new FormControl('', {
        validators: Validators.compose([Validators.required, Validators.minLength(2)]),
        updateOn: 'change',
      }),
    });
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private eventService: EventsAPI,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private _location: Location
  ) {
    this.rsvpFormNo = new FormGroup({
      parentName: new FormControl('', {
        validators: Validators.compose([Validators.required, Validators.minLength(2)]),
        updateOn: 'change',
      }),
      parentEmail: new FormControl('', {
        validators: Validators.compose([
          Validators.required,
          Validators.pattern(
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i
          ),
        ]),
        updateOn: 'change',
      }),

      kids: new FormArray([this.createItem()]),
    });

    this.validation_messages = {
      parentName: [
        { type: 'required', message: 'This field is required' },
        { type: 'minlength', message: 'This field must have at least 2 characters' },
      ],
      parentEmail: [
        { type: 'required', message: 'This field is required' },
        { type: 'pattern', message: 'Please enter a valid email' },
      ],
      kidNames: [
        { type: 'required', message: 'This field is required' },
        { type: 'minlength', message: 'This field must have at least 2 characters' },
      ],
    };
  }

  public addKid() {
    var kidsarray = this.rsvpFormNo.get('kids') as FormArray;
    kidsarray.push(this.createItem());
  }
  public removeKid(index: number) {
    var kidsarray = this.rsvpFormNo.get('kids') as FormArray;
    kidsarray.removeAt(index);
  }

  async ngOnInit() {
    await this.authService.isAuthenticated();
    this.route.params.subscribe(async (params) => {
      this.eventId = params['eventId'];
      const eventResponse = await this.eventService.getEventByShortCode(this.eventId).toPromise();
      const loadedEvent = eventResponse as PoolEvent;
      this.event = loadedEvent;
      this.eventDate = loadedEvent.startsAt;
      this.startTime = loadedEvent.startsAt;
      this.endTime = loadedEvent.endsAt;
      this.event = loadedEvent;
      console.log(this.event);
    });
  }

  async rsvp() {
    if (this.rsvpFormNo.status === 'INVALID') {
      this.rsvpFormNo.get('parentName').markAsTouched();
      this.rsvpFormNo.get('parentEmail').markAsTouched();
      //this.rsvpFormNo.get('kidNames').markAsTouched();
    } else {
      const kids = this.rsvpFormNo.get('kids') as FormArray;
      const kidNameStrings = kids.controls.map((c) => c.value.kidName);
      if (this.parentName && this.parentEmail) {
        sessionStorage.setItem('teddy.userEmail', this.parentEmail);
        await this.eventService.rsvp({
          eventKey: this.event.key,
          inviteeEmail: this.parentEmail,
          inviteeName: this.parentName,
          guests: kidNameStrings,
          rsvp: 'declined',
          // cardMessage: this.cardMessage
        });
        this.router.navigate([`/guest/${this.eventId}/rsvp/noconfirmed`]);
      }
    }
  }

  back() {
    this._location.back();
  }
}
