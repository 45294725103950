import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { PoolEvent, GiftPoolEvent } from 'src/app/models/event';
import { AuthAPI } from 'src/app/providers/authentication.api.service';
import { EventsAPI } from 'src/app/providers/events.api.service';
import { getDate, getTime } from 'src/app/shared/functions';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-guest-event-home',
  templateUrl: './guest-event-home.component.html',
  styleUrls: ['./guest-event-home.component.scss'],
})
export class GuestEventHomeComponent implements OnInit {
  eventKey: string;
  title: string;
  childName: string;
  eventDate: string;
  startTime: string;
  endTime: string;
  event: PoolEvent;
  partyDate: string;
  partyAddress: string;
  partyDetails: string;
  zoomLink: string;
  description: string;
  themes: any[];
  selectedThemeId: string;
  private token: string;
  giftpool: GiftPoolEvent;
  refreshContent: boolean = false;

  constructor(
    private authService: AuthenticationService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private authAPI: AuthAPI,
    private eventService: EventsAPI,
    private sanitize: DomSanitizer
  ) {
    // Event listener for viewport change
    const resizeCardContent = () => {
      // Observe window size
      const rightPanel = document.getElementById('party-content-area');
      if (rightPanel) {
        const rightPanelWidth = rightPanel.offsetWidth;

        if (rightPanelWidth > 575) {
          const cardCover = document.getElementById('bday-card-cover');
          const contentPanel = document.getElementById('bday-card-content');
          const rightWidth = cardCover.offsetWidth > rightPanelWidth / 2 ? rightPanelWidth / 2 : cardCover.offsetWidth;
          contentPanel.setAttribute('style', `width: ${rightWidth}px; height: ${cardCover.offsetHeight}px`);
        } else {
          const contentPanel = document.getElementById('bday-card-content');
          contentPanel.setAttribute('style', `width: ${rightPanelWidth}px;`);
        }
      }
    };
    window.addEventListener('resize', resizeCardContent);
    resizeCardContent();
  }

  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  async ngOnInit() {
    // Get token
    this.token = this.authAPI.anon_token;
    if (!this.token) {
      this.token = await this.authAPI.getAnonToken();
    }

    await this.authService.isAuthenticated();
    this.route.params.subscribe(async (params) => {
      this.eventKey = params['eventId'];

      // Get Themes
      const themes = await this.eventService.getThemes();
      console.log('---------- themes');
      console.log(themes);
      if (Array.isArray(themes) && themes.length > 0) {
        const formatted_themes = [];
        themes.forEach((theme) => {
          formatted_themes.push([
            theme.key,
            this.sanitize.bypassSecurityTrustResourceUrl(
              theme.invitationMedia.url + '?autoplay=1&loop=1&autopause=0&muted=1&background=1'
            ),
          ]);
        });
        this.themes = formatted_themes;
      }

      // Get Event
      const eventResponse = await this.eventService.getEventByShortCode(this.eventKey).toPromise();
      const loadedEvent = eventResponse as PoolEvent;
      this.event = loadedEvent;
      this.title = loadedEvent.title;
      this.childName = loadedEvent.kidsName;
      this.eventDate = getDate(this.event.startsAt);
      this.startTime = getTime(this.event.startsAt);
      this.partyDate = this.event.startsAt.substring(0, 10);
      this.endTime = getTime(this.event.endsAt);
      this.partyAddress = this.event.location;
      this.partyDetails = this.event.description;
      this.description = this.event.description;
      this.selectedThemeId = this.event.themeId || themes[0].key;

      //reinitialize addToCalendar button
      setTimeout(function () {
        (window as any).addeventatc.refresh();
      }, 100);
      console.log(this.event);

      // Check if gift pool exists
      const poolResponse = await this.eventService.getGiftPool(this.eventKey, this.token).toPromise();
      this.giftpool = poolResponse as GiftPoolEvent;
      console.log('---------- gift pool');
      console.log(this.giftpool);
    });
  }

  gotoGuestEventRSVP() {
    this.router.navigate(['/guest/event/rsvp/change']);
  }

  gotoToypool() {
    this.router.navigate(['/guest/event/toy']);
  }

  gotoGuestBirthdayCardSign() {
    this.router.navigate([`/guest/${this.eventKey}/birthdaycard/sign`]);
  }

  openModal(content) {
    this.modalService.open(content);
  }

  rsvpYes() {
    this.router.navigate([`/guest/${this.eventKey}/rsvp/yes`]);
  }

  rsvpNo() {
    this.router.navigate([`/guest/${this.eventKey}/rsvp/no`]);
  }

  chipIn() {}
  //20300101T205900Z <-- date format
  addToCalendar() {
    window.open(
      `data:text/calendar;charset=utf8,BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
URL:https://invitation.toymint.co/guest/${this.eventKey}
DTSTART:${this.event.startsAt}
DTEND:${this.event.endsAt}
SUMMARY:${this.event.kidsName}'s ${this.event.kidsAge}${
        this.event.kidsAge === 1 || this.event.kidsAge === 21 || this.event.kidsAge === 31
          ? 'st'
          : this.event.kidsAge == 2 || this.event.kidsAge === 22 || this.event.kidsAge === 32
          ? 'nd'
          : this.event.kidsAge == 3 || this.event.kidsAge === 23 || this.event.kidsAge === 33
          ? 'rd'
          : 'th'
      } Birthday Party
DESCRIPTION:${this.event.description}
END:VEVENT
END:VCALENDAR`,
      '_blank'
    );
  }

  refreshCollapsedContent() {
    // Observe window size
    const rightPanel = document.getElementById('party-content-area');
    if (rightPanel) {
      const rightPanelWidth = rightPanel.offsetWidth;

      if (rightPanelWidth > 575) {
        const cardCover = document.getElementById('bday-card-cover');
        const contentPanel = document.getElementById('bday-card-content');
        const rightWidth = cardCover.offsetWidth > rightPanelWidth / 2 ? rightPanelWidth / 2 : cardCover.offsetWidth;
        contentPanel.setAttribute('style', `width: ${rightWidth}px; height: ${cardCover.offsetHeight}px`);
      } else {
        const contentPanel = document.getElementById('bday-card-content');
        contentPanel.setAttribute('style', `width: ${rightPanelWidth}px;`);
      }
    }
  }

  public guestsIsCollapsed = false;
  public attendingGuestsIsCollapsed = false;
  public notAttendingGuestsIsCollapsed = false;
  public toypoolIsCollapsed = false;
  public birthdayGreetingsIsCollapsed = false;
}
