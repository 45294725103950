import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PoolEvent } from 'src/app/models/event';
import { AuthAPI } from 'src/app/providers/authentication.api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-manage-event-content',
  templateUrl: './manage-event-content.component.html',
  styleUrls: ['./manage-event-content.component.scss'],
})
export class ManageEventContentComponent implements OnInit {
  @Input() public placement: string = 'bottom-right';
  @Input() public numAttending: number = 0;
  @Input() public numNotAttending: number = 0;
  @Input() public showGuestList: boolean = false;
  @Input() public accountType: string = 'anonymous';
  @Input() private event: PoolEvent;
  @Output() editEvent = new EventEmitter<string>();
  @Output() sendInvite = new EventEmitter<string>();
  @Output() seeGuestList = new EventEmitter<string>();
  @Output() hideContent = new EventEmitter<string>();
  verifyEmailForm: FormGroup;
  hostEmail: string = '';
  validation_messages;
  tokenSent: boolean = true;

  constructor(private authAPI: AuthAPI, private modalService: NgbModal) {
    this.verifyEmailForm = new FormGroup({
      hostEmail: new FormControl(this.hostEmail, {
        validators: Validators.compose([
          Validators.required,
          Validators.pattern(
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i
          ),
        ]),
        updateOn: 'change',
      }),
    });

    this.validation_messages = {
      hostEmail: [
        { type: 'required', message: 'This field is required' },
        { type: 'pattern', message: 'Please enter a valid email' },
      ],
    };
  }

  ngOnInit() {
    this.hostEmail = this.event.hostEmail;
  }

  selectItem(item: string) {
    if (item === 'edit') {
      this.editEvent.emit();
    }
    if (item === 'invite') {
      this.sendInvite.emit();
    }
    if (item === 'guests') {
      this.seeGuestList.emit();
    }
  }

  hideManageContent() {
    this.hideContent.emit();
  }

  openModal(content) {
    this.modalService.open(content);
  }

  submitForm() {
    // if form is invalid,
    // touch all fields to reveal validator error messages
    if (this.verifyEmailForm.status === 'INVALID') {
      this.verifyEmailForm.get('hostEmail').markAsTouched();
    }
    // if form is valid,
    // create event
    else {
      this.authAPI.sendAuthEmail(this.hostEmail).subscribe((e) => {
        console.log('-------- sendAuthEmail response');
        console.log(e);
        this.tokenSent = true;
      });
    }
  }
}
