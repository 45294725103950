import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-login-password",
  templateUrl: "./login-password.component.html",
  styleUrls: ["./login-password.component.scss"],
})
export class LoginPasswordComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  back() {
    this.router.navigate(["/login/email"]);
  }

  gotoHostStart() {
    this.router.navigate(["/host/start"]);
  }

  gotoEventList() {
    this.router.navigate(["/events"]);
  }
}
