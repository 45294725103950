import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-event-edit",
  templateUrl: "./event-edit.component.html",
  styleUrls: ["./event-edit.component.scss"],
})
export class EventEditComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}
}
