import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../providers/authentication.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EventsAPI } from 'src/app/providers/events.api.service';
import { NavigationService } from '../../../providers/navigation.service';
import { getDateSuffix } from 'src/app/shared/functions';

@Component({
  selector: 'app-host-create-1',
  templateUrl: './host-create-1.component.html',
  styleUrls: ['./host-create-1.component.scss'],
})
export class HostCreate1Component implements OnInit {
  createInputPage1;
  validation_messages;
  public childName: string;
  public childAge: number;
  public creating: boolean = false;

  constructor(
    private router: Router,
    public eventsService: EventsAPI,
    private authService: AuthenticationService,
    private navigation: NavigationService
  ) {
    this.createInputPage1 = new FormGroup({
      childName: new FormControl(eventsService.kidsName, {
        validators: Validators.compose([Validators.required, Validators.minLength(2)]),
        updateOn: 'change',
      }),
      childAge: new FormControl(eventsService.kidsAge, {
        validators: Validators.compose([Validators.required, Validators.pattern(/^\d+$/)]),
        updateOn: 'change',
      }),
    });

    this.validation_messages = {
      childName: [
        { type: 'required', message: 'This field is required' },
        { type: 'minlength', message: 'This field must have at least 2 characters' },
      ],
      childAge: [
        { type: 'required', message: 'This field is required' },
        { type: 'pattern', message: 'Please enter a number' },
      ],
    };
  }

  async ngOnInit() {
    await this.authService.isAuthenticated();
  }

  gotoCreate2() {
    console.log(this.createInputPage1);
    if (this.createInputPage1.status === 'INVALID') {
      this.createInputPage1.get('childName').markAsTouched();
      this.createInputPage1.get('childAge').markAsTouched();
    } else {
      console.log(this.eventsService);
      // Construct default event title
      const kidsName = this.eventsService.kidsName;
      const kidsAge = this.eventsService.kidsAge;
      const formatted_age = kidsAge ? `${kidsAge}${getDateSuffix(kidsAge)} ` : '';
      this.eventsService.title = `${kidsName}'s ${formatted_age}Birthday Party`;
      // Go to next page
      this.router.navigate([`/host/create-2`]);
    }
  }

  back() {
    this.navigation.back();
  }
}
