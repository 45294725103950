import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '../../../providers/navigation.service';

@Component({
  selector: 'app-host-start',
  templateUrl: './host-start.component.html',
  styleUrls: ['./host-start.component.scss'],
})
export class HostStartComponent implements OnInit {
  public headline: string;

  constructor(private router: Router, private navigation: NavigationService) {}

  ngOnInit() {}

  gotoLoginPage() {
    console.log('gotoLoginPage()');
    this.router.navigate(['/login']);
  }

  gotoCreateInvite() {
    this.router.navigate(['/host/create-1']);
  }
}
