import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_LABEL_GLOBAL_OPTIONS } from '@angular/material';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login/login.component';
import { LoginEmailComponent } from './pages/login/login-email/login-email.component';
import { LoginCreateComponent } from './pages/login/login-create/login-create.component';
import { LoginPasswordComponent } from './pages/login/login-password/login-password.component';
import { EventListComponent } from './pages/events/event-list/event-list.component';
import { EventCreateComponent } from './pages/events/event-create/event-create.component';
import { EventEditComponent } from './pages/events/event-edit/event-edit.component';
import { HomeComponent } from './pages/home/home.component';
import { SafeHtmlPipe, SupportComponent } from './pages/support/support.component';
import { GuestEventHomeComponent } from './pages/guest/guest-event-home/guest-event-home.component';
import { GuestEventRsvpYesComponent } from './pages/guest/guest-event-rsvp-yes/guest-event-rsvp-yes.component';
import { GuestEventRsvpNoComponent } from './pages/guest/guest-event-rsvp-no/guest-event-rsvp-no.component';
import { GuestEventRsvpYesConfirmedComponent } from './pages/guest/guest-event-rsvp-yes-confirmed/guest-event-rsvp-yes-confirmed.component';
import { GuestEventRsvpNoConfirmedComponent } from './pages/guest/guest-event-rsvp-no-confirmed/guest-event-rsvp-no-confirmed.component';
import { GuestEventRsvpChangeComponent } from './pages/guest/guest-event-rsvp-change/guest-event-rsvp-change.component';
import { GuestEventToyComponent } from './pages/guest/guest-event-toy/guest-event-toy.component';
import { GuestEventToyContributeComponent } from './pages/guest/guest-event-toy-contribute/guest-event-toy-contribute.component';
import { GuestEventBirthdayCardComponent } from './pages/guest/guest-event-birthday-card/guest-event-birthday-card.component';
import { GuestEventBirthdayCardSignedComponent } from './pages/guest/guest-event-birthday-card-signed/guest-event-birthday-card-signed.component';
import { GuestEventBirthdayCardSignComponent } from './pages/guest/guest-event-birthday-card-sign/guest-event-birthday-card-sign.component';
import { GuestEventBirthdayCardEditComponent } from './pages/guest/guest-event-birthday-card-edit/guest-event-birthday-card-edit.component';
import { HostStartComponent } from './pages/host/host-start/host-start.component';
import { HostCreate1Component } from './pages/host/host-create-1/host-create-1.component';
import { HostCreate2Component } from './pages/host/host-create-2/host-create-2.component';
import { HostCreate3Component } from './pages/host/host-create-3/host-create-3.component';
import { HostCreateToypoolComponent } from './pages/host/host-create-toypool/host-create-toypool.component';
import { HostCreateToypoolTermsComponent } from './pages/host/host-create-toypool-terms/host-create-toypool-terms.component';
import { HostCreateToypoolGoodieBoxesComponent } from './pages/host/host-create-toypool-goodie-boxes/host-create-toypool-goodie-boxes.component';
import { HostCreateToypoolCheckoutComponent } from './pages/host/host-create-toypool-checkout/host-create-toypool-checkout.component';
import { HostCreatePreviewComponent } from './pages/host/host-create-preview/host-create-preview.component';
import { HostCreateInviteComponent } from './pages/host/host-create-invite/host-create-invite.component';
import { HostCreateAdminsComponent } from './pages/host/host-create-admins/host-create-admins.component';
import { EmailTemplateComponent } from './pages/email/email-template/email-template.component';
import { HostCreateToypoolChooseComponent } from './pages/host/host-create-toypool-choose/host-create-toypool-choose.component';
import { ToypoolCarouselComponent } from './components/toypool-carousel/toypool-carousel.component';
import { BirthdayGreetingsComponent } from './components/birthday-greetings/birthday-greetings.component';
import { WhatIsToypoolGuestComponent } from './components/modals/what-is-toypool-guest/what-is-toypool-guest.component';
import { HeaderDropdownMenuComponent } from './components/header-dropdown-menu/header-dropdown-menu.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireModule } from 'angularfire2';
import { GraphQLModule } from './graphql.module';
import { environment } from '../environments/environment';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { QrCodeModule } from 'ng-qrcode';
import { SanitizeHtml } from './sanitize-html';
import { SanitizeUrl } from './sanitize-url';
import { EditableComponent } from './components/editable/editable.component';
import { ViewModeDirective } from './components/editable/view-mode.directive';
import { EditModeDirective } from './components/editable/edit-mode.directive';
import { FocusableDirective } from './focusable.directive';
import { EditableOnEnterDirective } from './components/editable/editable-on-enter.directive';
import { HeaderComponent } from './components/header/header.component';
import { ContentWrapperComponent } from './components/content-wrapper/content-wrapper.component';
import { CollapsableSectionComponent } from './components/collapsable-section/collapsable-section.component';
import { HomeContentComponent } from './components/home-content/home-content.component';
import { SupportContentComponent } from './components/support-content/support-content.component';
import { FooterNavComponent } from './components/footer-nav/footer-nav.component';
import { FooterNavAltComponent } from './components/footer-nav-alt/footer-nav-alt.component';
import { BdayVideoComponent } from './components/bday-video/bday-video.component';
import { ManageEventDropdownComponent } from './components/manage-event-dropdown/manage-event-dropdown.component';
import { HostEditEventComponent } from './pages/host/host-edit-event/host-edit-event.component';
import { HostBdayCardSignComponent } from './pages/host/host-bday-card-sign/host-bday-card-sign.component';
import { BirthdayGreetingsSignComponent } from './components/birthday-greetings-sign/birthday-greetings-sign.component';
import { ManageEventContentComponent } from './components/manage-event-content/manage-event-content.component';
import { BdayVideoCarouselComponent } from './components/bday-video-carousel/bday-video-carousel.component';
import { HostCreateGiftpoolComponent } from './pages/host/host-create-giftpool/host-create-giftpool.component';
import { LoadingFullScreenComponent } from './components/loading-full-screen/loading-full-screen.component';
import { SecondaryHeaderComponent } from './components/secondary-header/secondary-header.component';
import { HostCreateGiftpoolConfirmComponent } from './pages/host/host-create-giftpool-confirm/host-create-giftpool-confirm.component';
import { HostEditGiftpoolComponent } from './pages/host/host-edit-giftpool/host-edit-giftpool.component';
import { GiftpoolSectionComponent } from './components/giftpool-section/giftpool-section.component';
import { HostGiftpoolContributionsComponent } from './pages/host/host-giftpool-contributions/host-giftpool-contributions.component';
import { GiftpoolFaqComponent } from './components/giftpool-faq/giftpool-faq.component';
import { GuestGiftpoolContributeComponent } from './pages/guest/guest-giftpool-contribute/guest-giftpool-contribute.component';
import { GuestGiftpoolConfirmedComponent } from './pages/guest/guest-giftpool-confirmed/guest-giftpool-confirmed.component';
import { PrivacyPolicyComponent } from './pages/terms/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './pages/terms/terms-of-use/terms-of-use.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginEmailComponent,
    LoginCreateComponent,
    LoginPasswordComponent,
    EventListComponent,
    EventCreateComponent,
    EventEditComponent,
    HomeComponent,
    SupportComponent,
    GuestEventHomeComponent,
    GuestEventRsvpYesComponent,
    GuestEventRsvpNoComponent,
    GuestEventRsvpYesConfirmedComponent,
    GuestEventRsvpNoConfirmedComponent,
    GuestEventRsvpChangeComponent,
    GuestEventToyComponent,
    GuestEventToyContributeComponent,
    GuestEventBirthdayCardComponent,
    GuestEventBirthdayCardSignedComponent,
    GuestEventBirthdayCardSignComponent,
    GuestEventBirthdayCardEditComponent,
    HostStartComponent,
    EmailTemplateComponent,
    HostCreate1Component,
    HostCreate2Component,
    HostCreate3Component,
    HostCreateToypoolComponent,
    HostCreateToypoolTermsComponent,
    HostCreateToypoolGoodieBoxesComponent,
    HostCreateToypoolCheckoutComponent,
    HostCreatePreviewComponent,
    HostCreateInviteComponent,
    HostCreateAdminsComponent,
    HostCreateToypoolChooseComponent,
    ToypoolCarouselComponent,
    BirthdayGreetingsComponent,
    WhatIsToypoolGuestComponent,
    HeaderDropdownMenuComponent,
    EditableComponent,
    ViewModeDirective,
    EditModeDirective,
    FocusableDirective,
    EditableOnEnterDirective,
    HeaderComponent,
    ContentWrapperComponent,
    CollapsableSectionComponent,
    HomeContentComponent,
    SupportContentComponent,
    FooterNavComponent,
    FooterNavAltComponent,
    BdayVideoComponent,
    ManageEventDropdownComponent,
    HostEditEventComponent,
    HostBdayCardSignComponent,
    BirthdayGreetingsSignComponent,
    ManageEventContentComponent,
    SanitizeHtml,
    SanitizeUrl,
    BdayVideoCarouselComponent,
    HostCreateGiftpoolComponent,
    LoadingFullScreenComponent,
    SecondaryHeaderComponent,
    HostCreateGiftpoolConfirmComponent,
    HostEditGiftpoolComponent,
    GiftpoolSectionComponent,
    HostGiftpoolContributionsComponent,
    GiftpoolFaqComponent,
    GuestGiftpoolContributeComponent,
    GuestGiftpoolConfirmedComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    SafeHtmlPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxMaterialTimepickerModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatExpansionModule,
    GraphQLModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    GooglePlaceModule,
    QrCodeModule,
  ],
  providers: [{ provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: { float: 'always' } }],
  bootstrap: [AppComponent],
})
export class AppModule {}
