import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PoolEvent } from 'src/app/models/event';
import { Principal, Account } from 'src/app/models/account';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { AuthAPI } from 'src/app/providers/authentication.api.service';
import { EventsAPI } from 'src/app/providers/events.api.service';
import { Strings } from 'src/app/shared/strings';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { getDate, getTime } from 'src/app/shared/functions';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-host-create-preview',
  templateUrl: './host-create-preview.component.html',
  styleUrls: ['./host-create-preview.component.scss'],
})
export class HostCreatePreviewComponent implements OnInit {
  accountType: string = 'anonymous';
  hostKey: string;
  event: PoolEvent;
  childName: string;
  startTime: string;
  endTime: string;
  partyDate: string;
  partyDetails: string;
  partyAddress: string;
  address: string;
  numAttending: number = 0;
  numNotAttending: number = 0;
  attending = [];
  notAttending = [];
  startOfDay: Date;
  editString = 'Edit Birthday Event';
  isEditing = false;
  description = '';
  showGuestList: boolean = false;
  showManageContent: boolean = true;
  // Themes
  selectingVideo: boolean = false;
  themes: any[];
  selectedThemeId: string;
  private acc_token: string;
  private anon_token: string;
  eventTransferred: boolean = false;
  hideEventTransferred: boolean = false;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private authAPI: AuthAPI,
    public eventService: EventsAPI,
    private authService: AuthenticationService,
    private sanitize: DomSanitizer
  ) {
    // Event listener for viewport change
    const resizeCardContent = () => {
      // Observe window size
      const rightPanel = document.getElementById('party-content-area');
      if (rightPanel) {
        const rightPanelWidth = rightPanel.offsetWidth;

        if (rightPanelWidth > 575) {
          const cardCover = document.getElementById('bday-card-cover');
          const contentPanel = document.getElementById('bday-card-content');
          const rightWidth = cardCover.offsetWidth > rightPanelWidth / 2 ? rightPanelWidth / 2 : cardCover.offsetWidth;
          contentPanel.setAttribute('style', `width: ${rightWidth}px; height: ${cardCover.offsetHeight}px`);
        } else {
          const contentPanel = document.getElementById('bday-card-content');
          contentPanel.setAttribute('style', `width: ${rightPanelWidth}px;`);
        }
      }
    };
    window.addEventListener('resize', resizeCardContent);
    resizeCardContent();
  }

  async ngOnInit() {
    await this.authService.isAuthenticated();

    // Get token
    this.acc_token = this.authAPI.acc_token;
    this.anon_token = this.authAPI.anon_token;
    if (!this.anon_token) {
      this.anon_token = await this.authAPI.getAnonToken();
    }

    if (this.acc_token) {
      // Get principal
      const principal = await this.authAPI.getAuthPrincipal(this.acc_token);
      const loadedPrincipal = principal as Principal;
      this.accountType = loadedPrincipal.type;
      this.hostKey = loadedPrincipal.key;
      console.log('------------- principal');
      console.log(loadedPrincipal);
    }

    this.route.params.subscribe(async (params) => {
      const eventId = params['eventId'];
      // Get url query params
      this.route.queryParams.subscribe((params) => {
        if (this.accountType === 'account') {
          // Get event data
          console.log('------- type = account');
          this.loadEvent(eventId, params);
        } else {
          if (params.account) {
            console.log('------- account found in params');
            // console.log(params);
            this.authAPI.setAccToken(params.account);
            this.acc_token = params.account;
            if (params.anonymous) {
              this.authAPI.setAnonToken(params.anonymous);
              this.anon_token = params.anonymous;
            }
            this.loadEvent(eventId, params);
          } else {
            console.log('------- type = anonymous');
            this.loadEvent(eventId);
          }
        }
      });
    });

    const show_manage_content = sessionStorage.getItem('toymint.manage');
    if (show_manage_content != null) {
      this.showManageContent = JSON.parse(show_manage_content);
    }
  }

  async transferOwnership(params, eventId: string) {
    try {
      console.log('--------- get account info');
      this.authAPI.getAccountInfo(params.account, this.hostKey).subscribe(
        (response) => {
          const loadedAccount = response as Account;
          const updateEventBody = {
            hostKey: this.hostKey,
            hostEmail: loadedAccount.email,
          };

          console.log('--------- transfer ownership');
          this.eventService.updateEvent(updateEventBody, eventId, params.anonymous).subscribe(
            (e) => {
              console.log(e);
              this.eventTransferred = true;
              setTimeout(() => {
                this.hideEventTransferred = true;
              }, 3000);
              setTimeout(() => {
                this.eventTransferred = false;
              }, 3500);
              this.loadEvent(eventId);
            },
            (e) => {
              // Error returned
              // console.log(e);
              this.resetPage();
            }
          );
        },
        (e) => {
          // Error returned
          this.resetPage();
        }
      );
    } catch (e) {
      console.log(e);
      this.loadEvent(eventId);
    }
  }

  async loadEvent(eventId: string, params = null) {
    // Get event details
    try {
      await this.getEventData(eventId, params);
    } catch {
      this.router.navigate(['/'], { queryParams: { event: 'not_found' } });
    }
  }

  async getEventData(eventId: string, params = null) {
    // Get Themes
    const themes = await this.eventService.getThemes();
    console.log('---------- themes');
    console.log(themes);
    if (Array.isArray(themes) && themes.length > 0) {
      const formatted_themes = [];
      themes.forEach((theme) => {
        if (theme.key && theme.invitationMedia && theme.invitationMedia.url) {
          formatted_themes.push([
            theme.key,
            this.sanitize.bypassSecurityTrustResourceUrl(
              theme.invitationMedia.url + '?autoplay=1&loop=1&autopause=0&muted=1&background=1'
            ),
          ]);
        }
      });
      this.themes = formatted_themes;
    }

    // Get Event
    const eventResponse = await this.eventService.getEvent(eventId, this.acc_token || this.anon_token).toPromise();
    const loadedEvent = eventResponse as PoolEvent;
    console.log('------------- loadedEvent');
    console.log(loadedEvent);
    this.event = loadedEvent;
    this.description = loadedEvent.title
      ? loadedEvent.title
      : `${loadedEvent.kidsName}'s ${loadedEvent.kidsAge}${this.nth(loadedEvent.kidsAge)} Birthday Party`;
    this.childName = loadedEvent.kidsName;
    this.partyDate = getDate(loadedEvent.startsAt);
    this.startTime = getTime(loadedEvent.startsAt);
    this.endTime = getTime(loadedEvent.endsAt);
    this.partyDetails = loadedEvent.description;
    this.partyAddress = loadedEvent.location;
    this.selectedThemeId = loadedEvent.themeId || themes[0].key;

    if (params !== null) {
      // Get account principal
      const principal = await this.authAPI.getAuthPrincipal(this.acc_token);
      const loadedPrincipal = principal as Principal;
      this.accountType = loadedPrincipal.type;
      this.hostKey = loadedPrincipal.key;

      console.log(loadedEvent.hostKey);
      console.log(this.hostKey);
      if (loadedEvent.hostKey !== this.hostKey) {
        await this.transferOwnership(params, loadedEvent.key);
      } else {
        this.getRSVPs(loadedEvent);
      }
    } else {
      this.getRSVPs(loadedEvent);
    }
  }

  resetPage() {
    this.authAPI.deleteAccountToken();
    this.router.navigate([`/host/${this.event.key}/giftpool`]);
  }

  async getRSVPs(event) {
    // Get RSVPs
    if (this.accountType === 'account') {
      const rsvps = await this.eventService.getInvitations(event.key);
      console.log('---------- rsvps');
      console.log('eventKey = ' + event.key);
      console.log(rsvps);
      this.attending = Array.isArray(rsvps) ? rsvps.filter((r) => r.rsvp === 'attending') : [];
      this.notAttending = Array.isArray(rsvps) ? rsvps.filter((r) => r.rsvp === 'declined') : [];
      this.numAttending = this.attending.reduce((acc, a) => {
        acc = acc + a.guests.length;
        return acc;
      }, 0); // attending.length;
      this.numNotAttending = this.notAttending.length;
    }

    //reinitialize addToCalendar button
    setTimeout(function () {
      (window as any).addeventatc.refresh();
    }, 100);
  }

  editEvent() {
    this.router.navigate([`/host/${this.event.key}/edit`]);
  }

  toggleGuestList() {
    this.showGuestList = !this.showGuestList;

    //reinitialize addToCalendar button
    setTimeout(function () {
      (window as any).addeventatc.refresh();
    }, 100);
  }

  toggleManageContent() {
    sessionStorage.setItem('toymint.manage', JSON.stringify(!this.showManageContent));
    this.showManageContent = !this.showManageContent;
  }

  openModal(content) {
    this.modalService.open(content);
  }

  gotoManageAdmins() {
    this.router.navigate([`/host/${this.event.key}/admins`]);
  }

  gotoSendInvite() {
    this.router.navigate([`/host/${this.event.key}/invite`]);
  }

  gotoGiftpool() {
    this.router.navigate([`/host/${this.hostKey}/giftpool`]);
  }

  rsvpYes() {
    this.router.navigate([`/guest/${this.event.key}/rsvp/yes`]);
  }

  rsvpNo() {
    this.router.navigate([`/guest/${this.event.key}/rsvp/no`]);
  }

  nth(d) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  updateTheme(themeId: string) {
    // console.log('UPDATING THEME');
    this.selectedThemeId = themeId;
    this.eventService.updateEvent({ themeId: themeId }, this.event.key, this.acc_token).subscribe((e) => {
      console.log('--------- theme updated');
      console.log(e);
    });
  }

  chipIn() {}

  public guestsIsCollapsed = false;
  public attendingGuestsIsCollapsed = false;
  public notAttendingGuestsIsCollapsed = false;
}
