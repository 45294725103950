import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PoolEvent } from 'src/app/models/event';
import { AuthAPI } from 'src/app/providers/authentication.api.service';
import { EventsAPI } from 'src/app/providers/events.api.service';
import { Invitation } from '../../../models/invitation';
import { AuthenticationService } from '../../../providers/authentication.service';
import { NavigationService } from '../../../providers/navigation.service';
import { environment } from 'src/environments/environment';
const uuid = require('uuid');

@Component({
  selector: 'app-host-create-invite',
  templateUrl: './host-create-invite.component.html',
  styleUrls: ['./host-create-invite.component.scss'],
})
export class HostCreateInviteComponent implements OnInit {
  eventId: string;
  loadedEvent: any;
  oHiddFrame: any;
  printing: boolean = false;
  sharingSupported: boolean = false;
  printingSupported: boolean = false;
  copyText = 'Copy Invite Link';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authAPI: AuthAPI,
    private eventService: EventsAPI,
    private authService: AuthenticationService,
    private navigation: NavigationService
  ) {
    let nav = navigator as any;
    if (nav && nav.share) {
      this.sharingSupported = true;
    } else {
      this.sharingSupported = false;
    }

    let win = window as any;
    if (win.print) {
      this.printingSupported = true;
    } else {
      this.printingSupported = false;
    }
  }

  async ngOnInit() {
    const token = await this.authAPI.getAnonToken();
    await this.authService.isAuthenticated();
    this.route.params.subscribe(async (params) => {
      this.eventId = params['eventId'];
      const eventResponse = await this.eventService.getEvent(this.eventId, token).toPromise();
      // debugger;
      this.loadedEvent = eventResponse as PoolEvent;
    });
  }

  gotoToypoolAdmins() {
    this.router.navigate([`/host/${this.eventId}/admins`]);
  }

  gotoHostCreatePreview() {
    this.router.navigate([`/host/${this.eventId}`]);
  }

  copyToClipboard(text) {
    const elem = document.createElement('textarea');
    elem.value = text;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);
  }

  async copyInvite() {
    console.log('copying link');
    this.copyToClipboard(`https://${environment.APP_DOMAIN}/guest/${this.loadedEvent.key}`);
    this.copyText = 'Copied!';
    setTimeout(() => {
      this.copyText = 'Copy Invite Link';
    }, 1000);
  }

  async shareInvite() {
    let nav = navigator as any;
    await nav.share({
      title: `${this.loadedEvent.title}`,
      text: `You are invited to ${this.loadedEvent.kidsName}'s ${this.loadedEvent.kidsAge}${
        this.loadedEvent.kidsAge === 1 || 21 || 31
          ? 'st'
          : this.loadedEvent.kidsAge === 2 || 22 || 32
          ? 'nd'
          : this.loadedEvent.kidsAge === 3 || 23 || 33
          ? 'rd'
          : 'th'
      } Birthday Party. RSVP here:`,
      url: `https://${environment.APP_DOMAIN}/guest/${this.loadedEvent.key}`,
    });
  }

  printInvite() {
    this.printing = true;
    this.printPage(`/host/${this.eventId}/emailtemplate`);
  }

  setPrint() {
    setTimeout(() => {
      (this as any).contentWindow.focus(); // Required for IE
      (this as any).contentWindow.print();
    }, 500);
  }

  printPage(sURL) {
    this.oHiddFrame = document.createElement('iframe');
    this.oHiddFrame.onload = this.setPrint;
    this.oHiddFrame.style.position = 'fixed';
    this.oHiddFrame.style.right = '0';
    this.oHiddFrame.style.bottom = '0';
    this.oHiddFrame.style.width = '0';
    this.oHiddFrame.style.height = '0';
    this.oHiddFrame.style.border = '0';
    this.oHiddFrame.src = sURL;
    document.body.appendChild(this.oHiddFrame);

    setTimeout(() => {
      this.printing = false;
    }, 2000);
  }

  back() {
    this.navigation.back();
  }
}
