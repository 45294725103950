import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Account } from '../models/account';

@Injectable({
  providedIn: 'root',
})
export class AuthAPI {
  private REST_API_SERVER = environment.REST_API_SERVER;
  private CLIENT_ID = environment.CLIENT_ID;
  public anon_token: string = JSON.parse(localStorage.getItem('birthdayteddy.anon'));
  public acc_token: string = JSON.parse(localStorage.getItem('birthdayteddy.account'));
  private apiHeaders = { Client: this.CLIENT_ID, 'Content-Type': 'application/json' };

  constructor(private httpClient: HttpClient) {}

  async getAnonToken() {
    if (!this.anon_token) {
      this.anon_token = await this.httpClient
        .get(`${this.REST_API_SERVER}/api/auth`, { headers: this.apiHeaders, responseType: 'text' })
        .toPromise();
      localStorage.setItem('birthdayteddy.anon', JSON.stringify(this.anon_token));
      return this.anon_token;
    } else return this.anon_token;
  }

  async getNewAnonToken() {
    this.anon_token = await this.httpClient
      .get(`${this.REST_API_SERVER}/api/auth`, { headers: this.apiHeaders, responseType: 'text' })
      .toPromise();
    localStorage.setItem('birthdayteddy.anon', JSON.stringify(this.anon_token));
    return this.anon_token;
  }

  sendAuthEmail(email: string) {
    return this.httpClient
      .post(`${this.REST_API_SERVER}/api/auth`, { email: email }, { headers: this.apiHeaders })
      .pipe(map(() => {}));
  }

  getAccToken() {
    if (!this.acc_token) {
      const acc_token = localStorage.getItem('birthdayteddy.account');
      if (acc_token !== null) {
        return JSON.parse(acc_token);
      } else return null;
    } else return this.acc_token;
  }

  setAnonToken(token: string) {
    this.anon_token = token;
    localStorage.setItem('birthdayteddy.anon', JSON.stringify(token));
  }

  setAccToken(token: string) {
    this.acc_token = token;
    localStorage.setItem('birthdayteddy.account', JSON.stringify(token));
  }

  deleteAccountToken() {
    this.acc_token = null;
    localStorage.removeItem('birthdayteddy.account');
  }

  async getAuthPrincipal(token: string) {
    const headers = { Authorization: `Bearer ${token}` };
    return this.httpClient
      .get(`${this.REST_API_SERVER}/api/auth/principal`, { headers: { ...this.apiHeaders, ...headers } })
      .toPromise();
  }

  async getAuthClaims() {
    const token = await this.getAnonToken();
    const headers = { Authorization: `Bearer ${token}` };
    return this.httpClient
      .get(`${this.REST_API_SERVER}/api/auth/claims`, { headers: { ...this.apiHeaders, ...headers } })
      .toPromise();
  }

  getAccountInfo(token: string, accountKey: string) {
    const headers = { Authorization: `Bearer ${token}` };
    return this.httpClient
      .get(`${this.REST_API_SERVER}/api/accounts/${accountKey}`, { headers: { ...this.apiHeaders, ...headers } })
      .pipe(map((acc: Account) => acc));
  }
}
