import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/providers/authentication.service";

@Component({
  selector: "app-guest-event-rsvp-change",
  templateUrl: "./guest-event-rsvp-change.component.html",
  styleUrls: ["./guest-event-rsvp-change.component.scss"],
})
export class GuestEventRsvpChangeComponent implements OnInit {
  constructor(private router: Router,
    private authService: AuthenticationService,
  ) {}

  async ngOnInit() {
    await this.authService.isAuthenticated();
  }

  gotoRSVPYes() {
    this.router.navigate(["/guest/12341241/rsvp/yes"]);
  }

  gotoRSVPNo() {
    this.router.navigate(["/guest/12341241/rsvp/no"]);
  }
}
