import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { PoolEvent } from 'src/app/models/event';
import { EventsAPI } from 'src/app/providers/events.api.service';

@Component({
  selector: 'app-guest-event-birthday-card-signed',
  templateUrl: './guest-event-birthday-card-signed.component.html',
  styleUrls: ['./guest-event-birthday-card-signed.component.scss'],
})
export class GuestEventBirthdayCardSignedComponent implements OnInit {
  private eventId: string = '';
  event: PoolEvent;
  childName: string = '';
  eventKey: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private eventService: EventsAPI
  ) {}

  async ngOnInit() {
    await this.authService.isAuthenticated();

    this.route.params.subscribe(async (params) => {
      this.eventId = params['eventId'];
      const eventResponse = await this.eventService.getEventByShortCode(this.eventId).toPromise();
      const loadedEvent = eventResponse as PoolEvent;
      this.event = loadedEvent;
      this.childName = loadedEvent.kidsName;
      this.eventKey = loadedEvent.key;
    });
  }

  gotoGuestEventHome() {
    this.router.navigate([`/guest/${this.eventId}`]);
  }
}
