import { Component } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { AuthenticationService } from './providers/authentication.service';
import { StripeService } from './providers/stripe.service';
import { RouterOutlet } from '@angular/router';
import { slideInAnimation } from './animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation],
})
export class AppComponent {
  title = 'app';

  constructor(
    private afAuth: AngularFireAuth,
    private authService: AuthenticationService,
    private stripeService: StripeService
  ) {
    this.afAuth.authState.subscribe((state) => {
      if (state && state.uid) {
        localStorage.setItem('authUser', JSON.stringify(state));
        this.authService.isAnonymous = state.isAnonymous;
        this.authService.authenticationState.next(true);
        this.stripeService.setUpDatabase(state);
      } else {
        localStorage.removeItem('authUser');
      }
    });

    this.afAuth.auth.signInAnonymously().then((credentials) => {
      if (credentials.user.uid) {
        this.authService.authenticationState.next(true);
      } else {
        this.authService.authenticationState.next(false);
      }
    });

    // Event listener for viewport height
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', appHeight);
    appHeight();
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
}
