import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PoolEvent, GiftPoolEvent } from '../../../models/event';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { AuthAPI } from 'src/app/providers/authentication.api.service';
import { EventsAPI } from 'src/app/providers/events.api.service';
import { getDate, getTime } from 'src/app/shared/functions';

@Component({
  selector: 'app-guest-event-rsvp-no-confirmed',
  templateUrl: './guest-event-rsvp-no-confirmed.component.html',
  styleUrls: ['./guest-event-rsvp-no-confirmed.component.scss'],
})
export class GuestEventRsvpNoConfirmedComponent implements OnInit {
  eventId: string;
  invitationCode: string;
  event: PoolEvent;
  eventDate: string;
  startTime: string;
  endTime: string;
  private token: string;
  giftpool: GiftPoolEvent;
  dataLoaded: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authAPI: AuthAPI,
    private eventService: EventsAPI,
    private modalService: NgbModal,
    private authService: AuthenticationService
  ) {}

  async ngOnInit() {
    // Get token
    this.token = this.authAPI.anon_token;
    if (!this.token) {
      this.token = await this.authAPI.getAnonToken();
    }

    await this.authService.isAuthenticated();
    this.route.params.subscribe(async (params) => {
      this.eventId = params['eventId'];
      const eventResponse = await this.eventService.getEventByShortCode(this.eventId).toPromise();
      const loadedEvent = eventResponse as PoolEvent;
      this.event = loadedEvent;
      this.eventDate = getDate(this.event.startsAt);
      this.startTime = getTime(this.event.startsAt);
      this.endTime = getTime(this.event.endsAt);
      console.log(this.event);

      // Check if gift pool exists
      try {
        const poolResponse = await this.eventService.getGiftPool(this.eventId, this.token).toPromise();
        this.giftpool = poolResponse as GiftPoolEvent;
        console.log('---------- gift pool');
        console.log(this.giftpool);
      } catch {}

      this.dataLoaded = true;
    });
  }

  gotoEventToy() {
    this.router.navigate([`/guest/${this.event.key}/toy`]);
  }

  gotoEventBirthdayCard() {
    this.router.navigate([`/guest/${this.eventId}/birthdaycard`]);
  }

  gotoGiftPool() {
    this.router.navigate([`/guest/${this.eventId}/giftpool/contribute`]);
  }

  gotoChangeRSVP() {
    this.router.navigate([`/guest/${this.event.key}/rsvp/change`]);
  }

  gotoEvent() {
    this.router.navigate([`/guest/${this.event.key}`]);
  }

  openModal(content) {
    this.modalService.open(content);
  }
}
