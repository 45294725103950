import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { EventsAPI } from 'src/app/providers/events.api.service';

@Component({
  selector: 'app-manage-event-dropdown',
  templateUrl: './manage-event-dropdown.component.html',
  styleUrls: ['./manage-event-dropdown.component.scss'],
  animations: [
    trigger('dropdownOpenClose', [
      state(
        'open',
        style({
          opacity: 1,
          marginTop: 0,
        })
      ),
      state(
        'closed',
        style({
          opacity: 0,
          marginTop: '10px',
        })
      ),
      transition('open => closed', [animate('100ms ease')]),
      transition('closed => open', [animate('100ms ease')]),
    ]),
  ],
})
export class ManageEventDropdownComponent implements OnInit {
  @Input() public placement: string = 'bottom-right';
  @Input() public numAttending: number = 0;
  @Input() public numNotAttending: number = 0;
  @Input() public showGuestList: boolean = false;
  @Output() editEvent = new EventEmitter<string>();
  @Output() sendInvite = new EventEmitter<string>();
  @Output() seeGuestList = new EventEmitter<string>();

  constructor(private router: Router, public eventsService: EventsAPI) {}

  ngOnInit() {}

  selectItem(item: string) {
    if (item === 'edit') {
      this.editEvent.emit();
    }
    if (item === 'invite') {
      this.sendInvite.emit();
    }
    if (item === 'guests') {
      this.seeGuestList.emit();
    }
  }

  gotoPage(page: string) {
    this.router.navigate([`/${page}`]);
  }

  goHome() {
    this.eventsService.resetEvent();
    this.router.navigate(['/']);
  }
}
