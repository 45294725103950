import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AuthAPI } from 'src/app/providers/authentication.api.service';
import { EventsAPI } from 'src/app/providers/events.api.service';
import { PoolEvent } from 'src/app/models/event';

@Component({
  selector: 'app-host-bday-card-sign',
  templateUrl: './host-bday-card-sign.component.html',
  styleUrls: ['./host-bday-card-sign.component.scss'],
})
export class HostBdayCardSignComponent implements OnInit {
  private token: string;
  hostKey: string = '';
  eventId: string = '';

  constructor(
    private route: ActivatedRoute,
    private authAPI: AuthAPI,
    public eventService: EventsAPI,
    private _location: Location
  ) {}

  async ngOnInit() {
    // Get token
    this.token = this.authAPI.anon_token;
    if (!this.token) {
      this.token = await this.authAPI.getAnonToken();
    }

    this.route.params.subscribe(async (params) => {
      this.eventId = params['eventId'];
      const eventResponse = await this.eventService.getEventByShortCode(this.eventId).toPromise();
      console.log(eventResponse);
    });
  }

  back() {
    this._location.back();
  }
}
