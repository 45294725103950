import { Component, ViewChild, OnInit } from '@angular/core';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { PoolEvent } from 'src/app/models/event';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { AuthAPI } from 'src/app/providers/authentication.api.service';
import { EventsAPI } from 'src/app/providers/events.api.service';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Strings } from 'src/app/shared/strings';
import { getDate, getTime, getTimePartials } from 'src/app/shared/functions';
// import { getTime, formatHours, getAMPM, getTimePartials } from 'src/app/shared/functions';

@Component({
  selector: 'app-host-edit-event',
  templateUrl: './host-edit-event.component.html',
  styleUrls: ['./host-edit-event.component.scss'],
})
export class HostEditEventComponent implements OnInit {
  eventId: string;
  event: PoolEvent;
  eventLoaded: boolean = false;
  kidsName: string;
  kidsAge: number;
  startTime: string;
  endTime: string;
  partyDate: Date;
  partyDetails: string;
  previewInput;
  title: string = '';
  location: string;
  validation_messages: {
    title: { type: string; message: string }[];
    kidsName: { type: string; message: string }[];
    partyDate: { type: string; message: string }[];
    startTime: { type: string; message: string }[];
    endTime: { type: string; message: string }[];
    partyLocation: { type: string; message: string }[];
    // zoomLink: { type: string; message: string }[];
    // mustHaveOne: { message: string };
  };
  private token: string;
  updateError: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authAPI: AuthAPI,
    public eventService: EventsAPI,
    private authService: AuthenticationService
  ) {
    this.validation_messages = {
      title: [{ type: 'required', message: Strings.FieldRequired }],
      kidsName: [{ type: 'required', message: Strings.FieldRequired }],
      partyDate: [
        { type: 'required', message: Strings.FieldRequired },
        { type: 'invalid', message: 'Event cannot be in the past' },
      ],
      startTime: [
        { type: 'required', message: Strings.FieldRequired },
        { type: 'invalid', message: 'Event cannot be in the past' },
      ],
      endTime: [{ type: 'required', message: Strings.FieldRequired }],
      partyLocation: [{ type: 'custom', message: Strings.PartyLocationWebLinkRequired }],
      // zoomLink: [{ type: 'pattern', message: Strings.ZoomLinkPattern }],
      // mustHaveOne: { message: Strings.PartyLocationWebLinkRequired },
    };

    this.route.params.subscribe(async (params) => {
      this.token = this.authAPI.acc_token || this.authAPI.anon_token;
      this.eventId = params['eventId'];
      const eventResponse = await this.eventService.getEvent(this.eventId, this.token).toPromise();
      const loadedEvent = eventResponse as PoolEvent;
      this.event = loadedEvent;

      this.startTime = getTime(this.event.startsAt);
      this.endTime = getTime(this.event.endsAt);
      this.partyDate = new Date(this.event.startsAt); // Needs to be Date format

      this.previewInput = new FormGroup({
        kidsName: new FormControl(this.event.kidsName),
        kidsAge: new FormControl(this.event.kidsAge),
        partyDate: new FormControl(this.partyDate),
        startTime: new FormControl(this.startTime),
        endTime: new FormControl(this.endTime),
        title: new FormControl(this.event.title),
        // zoomLinkField: new FormControl(this.zoomLink),
        partyDetails: new FormControl(this.event.description),
        partyLocation: new FormControl(this.event.location),
      });

      this.eventLoaded = true;
    });
  }

  @ViewChild('autosize', { static: false }) autosize: CdkTextareaAutosize;

  async ngOnInit() {
    await this.authService.isAuthenticated();
  }

  // mustHaveOne(): ValidatorFn {
  //   return (control: AbstractControl): ValidationErrors | null => {
  //     return !this.event.location && !this.event.zoomLink ? { mustHaveOne: { value: control.value } } : null;
  //   };
  // }

  /* Edit In Place methods */
  onTitleChange(event) {
    this.title = event.target.value;
    this.event.title = event.target.value;
  }

  // /* Edit In Place methods */
  onDetailsChange(event) {
    this.event.description = event.target.value;
  }

  invalidEventStart(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return { invalid: { value: control.value } };
    };
  }

  handleDateValidation() {
    const now = moment().unix();
    const starts_at = Date.parse(this.event.startsAt) / 1000;

    console.log(`startTime (${starts_at}) < Now (${now}): ${starts_at < now}`);

    // if start time is earlier than end time
    if (starts_at > now) {
      this.previewInput.get('partyDate').clearValidators();
      this.previewInput.get('partyDate').updateValueAndValidity();
      this.previewInput.get('startTime').clearValidators();
      this.previewInput.get('startTime').updateValueAndValidity();
    }
    // set error
    else {
      this.previewInput.get('partyDate').setValidators(this.invalidEventStart());
      this.previewInput.get('partyDate').updateValueAndValidity();
      this.previewInput.get('partyDate').markAsTouched();
      this.previewInput.get('startTime').setValidators(this.invalidEventStart());
      this.previewInput.get('startTime').updateValueAndValidity();
      this.previewInput.get('startTime').markAsTouched();
    }
  }

  handleTimes() {
    // Update eventsService.startsAt
    const [s_hours, s_mins, s_ampm] = getTimePartials(this.startTime);
    let new_start_date = new Date(this.event.startsAt);
    new_start_date.setHours(Number(s_hours), Number(s_mins));
    this.event.startsAt = new_start_date.toISOString();
    console.log('this.event.startsAt: ' + this.event.startsAt);

    // Update eventsService.endsAt
    if (this.endTime) {
      let new_end_date = new Date(this.event.startsAt);
      const [e_hours, e_mins, e_ampm] = getTimePartials(this.endTime);

      // endsAt is next day if "endTime" is earlier than or same as "startTime"
      if (e_hours <= s_hours) {
        new_end_date.setDate(new_end_date.getDate() + 1);
      }

      new_end_date.setHours(Number(e_hours), Number(e_mins));
      this.event.endsAt = new_end_date.toISOString();
      console.log('this.events.endsAt = ' + this.event.endsAt);
    }
  }

  onDateChange(date: any) {
    // console.log(date);
    // date = MatDatepickerInputEvent object
    // Transform to Intl.DateTimeFormat
    // E.g. Fri Jun 30 2023 00:00:00 GMT-0700 (Pacific Daylight Time)
    const date_obj = new Date(date.value);

    // Add startTime hours & mins to date
    const [s_hours, s_mins, s_ampm] = getTimePartials(this.startTime);
    date_obj.setHours(Number(s_hours), Number(s_mins));

    // Handle party date
    this.partyDate = date_obj;
    this.event.startsAt = date_obj.toISOString(); // "2011-10-05T14:48:00.000Z" Format

    // Handle party times
    this.handleTimes();

    // Validate Date
    this.handleDateValidation();
  }

  // startDate change
  startTimeChanged(time: any) {
    try {
      // time = 7:00 PM format
      // Update startTime
      this.startTime = time;
      this.handleTimes();

      // Validate Date
      this.handleDateValidation();
    } catch (e) {
      console.log(e);
    }
  }

  // endDate change
  endTimeChanged(time: any) {
    // time = 7:00 PM format
    // Update endTime
    this.endTime = time;
    this.handleTimes();
  }

  public handleLocationChange(address) {
    console.log('Address:');
    console.log(address);
    // Delete address content if the input field is empty
    if (address.name === '') {
      this.location = '';
      this.previewInput.get('partyLocation').updateValueAndValidity();
    } else {
      // this.location = address.formatted_address;
      this.event.location = address.formatted_address;
    }
  }

  public updateAddress(event) {
    if (event.target.value === '') {
      this.handleLocationChange({ name: '' });
    }
  }

  nth(d) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  goToHostEventPage() {
    this.router.navigate([`/host/${this.eventId}`]);
    this.updateError = '';
  }

  saveChanges() {
    const event = new PoolEvent();
    this.updateError = '';
    event.title = this.event.title;
    event.kidsName = this.event.kidsName;
    event.kidsAge = Number(this.event.kidsAge);
    event.startsAt = this.event.startsAt;
    event.endsAt = this.event.endsAt;
    event.location = this.event.location;
    event.description = this.event.description;

    this.eventService.updateEvent(event, this.eventId, this.token).subscribe(
      (e) => {
        console.log('--------- changes saved');
        console.log(e);
        this.goToHostEventPage();
      },
      (e) => {
        // Error returned
        console.log('--------- updateEvent error');
        console.log(e);
        this.updateError = Strings.RequestErrorMessage;
      }
    );
  }
}
