import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-guest-event-birthday-card-sign',
  templateUrl: './guest-event-birthday-card-sign.component.html',
  styleUrls: ['./guest-event-birthday-card-sign.component.scss'],
})
export class GuestEventBirthdayCardSignComponent implements OnInit {
  eventId: string = '';

  constructor(private _location: Location) {}

  async ngOnInit() {
    const event_id_partial = window.location.href.split('/guest/')[1];
    this.eventId = event_id_partial.split('/')[0];
  }

  back() {
    this._location.back();
  }
}
