import { Component, ContentChild, HostListener, ElementRef, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { ViewModeDirective } from './view-mode.directive';
import { EditModeDirective } from './edit-mode.directive';
import { NgControl } from '@angular/forms';
import { fromEvent, Subject } from 'rxjs';
import { switchMap, takeUntil, filter, take, switchMapTo } from 'rxjs/operators';

@Component({
  selector: 'editable',
  template: `
    <ng-container *ngTemplateOutlet="currentView"></ng-container>
  `,
  styleUrls: ['./editable.component.css']
})
export class EditableComponent {
  @ContentChild(ViewModeDirective,{static: false}) viewModeTpl: ViewModeDirective;
  @ContentChild(EditModeDirective,{static: false}) editModeTpl: EditModeDirective;
  @Output() update = new EventEmitter();

  editMode = new Subject();
  editMode$ = this.editMode.asObservable();

  mode: 'view' | 'edit' = 'view';


  constructor(private host: ElementRef) {
  }

  ngOnInit() {
    
  }

  toViewMode() {
    this.update.next();
    this.mode = 'view';
  }

  @Input() set isEditing(value: boolean) {
    if (value) {
      this.mode = 'edit';
      this.editMode.next(true);
    }
    else {
      this.toViewMode()
    }
 
 }
 
 get categoryId(): boolean {
 
     return this.mode == 'edit';
 
 }

  private get element() {
    return this.host.nativeElement;
  }

  get currentView() {
    return this.mode === 'view' ? this.viewModeTpl.tpl : this.editModeTpl.tpl;
  }

  ngOnDestroy() {
  }

}