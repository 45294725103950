import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '../../providers/navigation.service';
import { AuthAPI } from 'src/app/providers/authentication.api.service';
import { EventsAPI } from 'src/app/providers/events.api.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public message: string = 'invite';
  private store_name: string = 'birthdayteddy.home';
  public loginShown: boolean = false;
  public account: boolean = false;
  public events: boolean = false;
  public event_not_found: boolean = false;

  constructor(
    private router: Router,
    private navigation: NavigationService,
    private authAPI: AuthAPI,
    public eventsService: EventsAPI
  ) {}

  async ngOnInit() {
    const stored_value = localStorage.getItem(this.store_name);
    if (stored_value !== null) {
      this.message = stored_value;
    }

    const stored_acc_token = this.authAPI.acc_token;
    if (stored_acc_token !== null) {
      this.account = true;
      this.getEvents(stored_acc_token);
    }

    // Handle token in route
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('account');
    if (token) {
      this.authAPI.setAccToken(token);
      window.history.replaceState({}, '', '/');
      this.account = true;

      this.getEvents(token);
    }

    const event = urlParams.get('event');
    if (event === 'not_found') {
      this.event_not_found = true;
    }
  }

  async getEvents(token: string) {
    const events = await this.eventsService.getEvents(token);
    // console.log(events);
    if (events) {
      this.events = true;
    }
  }

  gotoCreateInvite() {
    this.router.navigate(['/host/create-1']);
  }

  toggleMessage(str: string) {
    localStorage.setItem(this.store_name, str);
    this.message = str;
  }

  toggleLogin() {
    this.loginShown = !this.loginShown;
  }

  gotoPage(page: string) {
    this.router.navigate([`/${page}`]);
  }

  gotoEvents() {
    this.router.navigate([`/events`]);
  }

  reset() {
    this.event_not_found = false;
    window.history.replaceState({}, '', '/');
  }

  logout() {
    this.authAPI.deleteAccountToken();
    this.account = false;
  }
}
