import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PoolEvent } from '../../../models/event';
import { Principal, Account } from 'src/app/models/account';
import * as moment from 'moment';
import { AuthenticationService } from '../../../providers/authentication.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthAPI } from 'src/app/providers/authentication.api.service';
import { EventsAPI } from 'src/app/providers/events.api.service';
import { NavigationService } from '../../../providers/navigation.service';
import { Strings } from 'src/app/shared/strings';

@Component({
  selector: 'app-host-create-3',
  templateUrl: './host-create-3.component.html',
  styleUrls: ['./host-create-3.component.scss'],
})
export class HostCreate3Component implements OnInit {
  createInputPage3;
  validation_messages;
  public creating: boolean = false;
  private token: string;
  createEventError: string;

  constructor(
    private router: Router,
    private authAPI: AuthAPI,
    public eventsService: EventsAPI,
    private authService: AuthenticationService,
    private navigation: NavigationService
  ) {
    this.createInputPage3 = new FormGroup({
      parentName: new FormControl(this.eventsService.hostName, {
        validators: Validators.required,
        updateOn: 'change',
      }),
      parentEmail: new FormControl(this.eventsService.hostEmail, {
        validators: Validators.compose([
          Validators.required,
          Validators.pattern(
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i
          ),
        ]),
        updateOn: 'change',
      }),
    });

    this.validation_messages = {
      parentName: [{ type: 'required', message: 'This field is required' }],
      parentEmail: [
        { type: 'required', message: 'This field is required' },
        { type: 'pattern', message: 'Please enter a valid email' },
      ],
    };
  }

  async ngOnInit() {
    await this.authService.isAuthenticated();
    this.token = await this.authAPI.getAnonToken();
  }

  letters = 'abcdefghijklmnopqrstuvwxyz';
  numbers = '1234567890';
  charset = this.letters + this.letters.toUpperCase() + this.numbers;

  randomElement(array) {
    return array[Math.floor(Math.random() * array.length)];
  }

  randomString(length) {
    var R = '';
    for (var i = 0; i < length; i++) R += this.randomElement(this.charset);
    return R;
  }

  async handleNewToken(event) {
    this.authAPI.deleteAccountToken();
    const new_token = await this.authAPI.getNewAnonToken();
    this.token = new_token;

    this.eventsService.createEvent(event, new_token).subscribe((e) => {
      console.log('-------- eventService response');
      console.log(e);

      this.router.navigate([`/host/${e.key}`]);
    });
  }

  async gotoInvitePreview() {
    this.createEventError = '';
    // if form is invalid,
    // touch all fields to reveal validator error messages
    if (this.createInputPage3.status === 'INVALID') {
      this.createInputPage3.get('parentName').markAsTouched();
      this.createInputPage3.get('parentEmail').markAsTouched();
    }
    // if form is valid,
    // create event
    else {
      this.creating = true;
      const event = new PoolEvent();
      event.kidsName = this.eventsService.kidsName;
      event.title = this.eventsService.title;
      event.startsAt = this.eventsService.startsAt;
      if (this.eventsService.endsAt) {
        event.endsAt = this.eventsService.endsAt;
      }
      event.location = this.eventsService.location;
      // event.address = this.eventsService.placeAddress;
      // event.city = this.eventsService.placeCity;
      // event.country = this.eventsService.placeCountry;
      // event.postal = this.eventsService.placePostal;
      // event.rsvpDate = this.eventsService.rsvpDate;
      // event.requireRsvp = true;
      // event.zoomLink = this.eventsService.zoomLink;
      if (this.eventsService.description) {
        event.description = this.eventsService.description;
      }
      event.hostName = this.eventsService.hostName;
      event.hostEmail = this.eventsService.hostEmail;
      // event.toyPool = false;
      event.kidsAge = Number(this.eventsService.kidsAge);
      // event.product = undefined;
      event.includeCard = false;
      event.createdAt = new Date().toISOString();

      // console.log(this.eventsService);
      console.log(event);

      if (this.authAPI.acc_token) {
        // Get account principal
        const principal = await this.authAPI.getAuthPrincipal(this.authAPI.acc_token);
        const loadedPrincipal = principal as Principal; // { type: str, key: str }

        // Get account info
        this.authAPI.getAccountInfo(this.authAPI.acc_token, loadedPrincipal.key).subscribe(
          (account) => {
            const loadedAccount = account as Account; // { key: str, email: str }

            // Reset tokens if account token email does not match new email entered
            if (loadedAccount.email !== event.hostEmail) {
              this.handleNewToken(event);
            } else {
              this.eventsService.createEvent(event, this.authAPI.acc_token).subscribe((e) => {
                console.log('-------- eventService response');
                console.log(e);

                this.router.navigate([`/host/${e.key}`]);
              });
            }
          },
          (e) => {
            this.createAnonEvent(event);
          }
        );
      } else {
        this.createAnonEvent(event);
      }
    }
  }

  createAnonEvent(event) {
    this.eventsService.createEvent(event, this.token).subscribe(
      (e) => {
        console.log('-------- eventService response');
        console.log(e);

        this.router.navigate([`/host/${e.key}`]);
      },
      (e) => {
        // Error returned
        console.log('--------- createEvent error');
        console.log(e);
        this.createEventError = Strings.RequestErrorMessage;
      }
    );
  }

  back() {
    this.navigation.back();
  }

  goHome() {
    this.eventsService.resetEvent();
    this.router.navigate(['/']);
  }

  nth = function (d) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };
}
