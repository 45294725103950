import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventsAPI } from 'src/app/providers/events.api.service';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { AuthAPI } from 'src/app/providers/authentication.api.service';
import { PoolEvent, GiftPoolEvent, Contributions, GiftpoolUpdateBody } from 'src/app/models/event';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Strings } from 'src/app/shared/strings';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-host-edit-giftpool',
  templateUrl: './host-edit-giftpool.component.html',
  styleUrls: ['./host-edit-giftpool.component.scss'],
})
export class HostEditGiftpoolComponent implements OnInit {
  private token: string;
  isLoading: boolean = true;
  eventId: string;
  event: PoolEvent;
  // Form
  editDescriptionForm: FormGroup;
  editEndDateForm: FormGroup;
  editEmailForm: FormGroup;
  cancelGiftpoolForm: FormGroup;
  public hostEmail: string;
  public description: string;
  public expiration: Date;
  public cancel: boolean;
  giftpoolExpiry: Date;
  giftpoolExpiryUnix: number;
  // Validation
  validation_messages;
  today: number = moment().startOf('day').unix();
  // Contributions
  contributions: Contributions[];
  giftpool: GiftPoolEvent;
  // Cancel gift pool
  cancelling: boolean = false;
  // Update gift pool
  updating: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authAPI: AuthAPI,
    public eventService: EventsAPI,
    private authService: AuthenticationService,
    private _location: Location,
    private modalService: NgbModal
  ) {
    this.editDescriptionForm = new FormGroup({
      description: new FormControl(this.description, {
        updateOn: 'change',
      }),
    });

    this.editEndDateForm = new FormGroup({
      expiration: new FormControl(this.giftpoolExpiry, {
        validators: Validators.required,
        updateOn: 'change',
      }),
    });

    this.editEmailForm = new FormGroup({
      email: new FormControl(this.hostEmail, {
        validators: [Validators.required, Validators.email],
        updateOn: 'change',
      }),
    });

    this.cancelGiftpoolForm = new FormGroup({
      cancel: new FormControl(this.cancel, {
        validators: [Validators.required],
        updateOn: 'change',
      }),
    });

    this.validation_messages = {
      email: [
        { type: 'required', message: Strings.FieldRequired },
        { type: 'email', message: Strings.EmailPattern },
      ],
      expiration: [
        { type: 'required', message: Strings.FieldRequired },
        { type: 'invalid', message: 'Cannot be a past date' },
      ],
      cancel: [
        { type: 'required', message: Strings.FieldRequired },
        // { type: 'invalid', message: 'Input must match CANCEL' },
      ],
    };
  }

  async ngOnInit() {
    // Get token
    this.token = this.authAPI.acc_token;
    if (!this.token) {
      // Redirect to host event page
      this.route.params.subscribe(async (params) => {
        this.router.navigate([`/host/${params['eventId']}`]);
      });
    }

    await this.authService.isAuthenticated();
    this.route.params.subscribe(async (params) => {
      await this.routeLoaded(params);
    });
  }

  async routeLoaded(params) {
    this.eventId = params['eventId'];
    const eventResponse = await this.eventService.getEvent(this.eventId, this.token).toPromise();
    this.event = eventResponse as PoolEvent;
    this.isLoading = false;

    console.log(this.event);

    // Get giftpool
    try {
      const poolResponse = await this.eventService.getGiftPool(this.event.key, this.token).toPromise();

      if ('id' in poolResponse) {
        this.giftpool = poolResponse as GiftPoolEvent;
        this.description = this.giftpool.description;
        console.log('---------- gift pool');
        console.log(this.giftpool);

        // Check if contributions exist
        if (this.giftpool) {
          // Get contributions
          const contributions = await this.eventService
            .getGiftPoolContributions(this.giftpool.id, this.token)
            .toPromise();
          this.contributions = contributions as Contributions[];
          console.log('---------- contributions');
          console.log(this.contributions);
        }
      }
    } catch (e) {
      // Redirect if not found
      this.router.navigate([`/host/${this.event.key}/giftpool`]);
    }
  }

  checkDate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.giftpoolExpiryUnix < this.today ? { invalid: { value: control.value } } : null;
    };
  }

  handleDateValidation(unixtimestamp: number) {
    // if start time later earlier than today
    if (unixtimestamp >= this.today) {
      this.editEndDateForm.get('expiration').clearValidators();
      this.editEndDateForm.get('expiration').updateValueAndValidity();
    }
    // set error
    else {
      this.editEndDateForm.get('expiration').setValidators(this.checkDate());
      this.editEndDateForm.get('expiration').updateValueAndValidity();
    }
  }

  // partyDate change
  onDateChange(date: any) {
    // date = MatDatepickerInputEvent object
    // Transform to Intl.DateTimeFormat
    // E.g. Fri Jun 30 2023 00:00:00 GMT-0700 (Pacific Daylight Time)
    const date_obj = new Date(date.value);
    this.giftpoolExpiry = date_obj;
    // this.giftpoolExpiry = date_obj.toISOString(); // "2011-10-05T14:48:00.000Z" Format
    console.log('eventsService.startsAt = ' + date_obj.toString());

    // Get moment objects and unixtimestamps
    const unixtimestamp = date_obj.getTime(); // unixtimestamp (milliseconds)
    const unix_seconds = unixtimestamp / 1000; // unixtimestamp (seconds)
    this.giftpoolExpiryUnix = unix_seconds;
    // const moment_obj = moment(unixtimestamp);
    // console.log('unix_seconds = ' + unix_seconds);
    this.handleDateValidation(unix_seconds);
  }

  back() {
    this._location.back();
  }

  async cancelGiftPool() {
    this.cancelling = true;
    const cancelResponse = await this.eventService.deleteGiftPool(this.event.key, this.token).toPromise();
    console.log('---------- giftpool cancelled');
    console.log(cancelResponse);
    // Close modal
    this.modalService.dismissAll();
    // Redirect to event page
    this.router.navigate([`/host/${this.event.key}`]);
  }

  updateGiftpoolData() {
    this.updating = true;

    const rq_body = {
      description: this.description,
    };
    this.eventService.updateGiftPool(this.event.key, this.token, rq_body).subscribe((resp: GiftPoolEvent) => {
      // Close modal
      this.modalService.dismissAll();
      this.updating = false;
      console.log('---------- giftpool updated');
      console.log(resp);
      this.giftpool = resp;
    });
  }

  updateEndDate() {
    // if form is invalid,
    // touch all fields to reveal validator error messages
    if (this.editEndDateForm.status === 'INVALID') {
      this.editEndDateForm.get('expiration').markAsTouched();
    }
    // close modal if form is valid,
    else {
      this.modalService.dismissAll();
    }
  }

  updateEmail() {
    // if form is invalid,
    // touch all fields to reveal validator error messages
    if (this.editEmailForm.status === 'INVALID') {
      this.editEmailForm.get('email').markAsTouched();
    }
    // close modal if form is valid,
    else {
      this.modalService.dismissAll();
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  openModal(content) {
    this.modalService.open(content);
  }

  gotoContributions() {
    this.router.navigate([`/host/${this.event.key}/giftpool/contributions`]);
  }
}
