import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GiftPoolEvent, GiftPoolCreateBody, GiftpoolUpdateBody, PoolEvent, Contributions } from '../models/event';
import { RSVP } from '../models/rsvps';
import { BirthdayGreetings } from '../models/birthday-greetings';
import { InvitationReqBody } from '../models/invitation';
import { forkJoin } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { RsaPrivateKey } from 'crypto';
import { AuthAPI } from 'src/app/providers/authentication.api.service';

class ApiRsvp {
  status: number;
}

// class ApiEvent {
//   Id: string;
//   Name: string;
//   ChildAge: number;
//   Description: string;
//   Location: string;
//   Address: string;
//   City: string;
//   Country: string;
//   Postal: string;
//   StartDate: Date;
//   EndDate: Date;
//   RequireRSVP: boolean;
//   MeetingURL: string;
//   MoreDetails: string;
//   ParentName: string;
//   ParentEmail: string;
//   RSVPDate: Date;
//   Rsvps: ApiRsvp[];
// }
class ApiEvent {
  key: string;
  hostKey: string;
  hostName: string;
  hostEmail: string;
  createdAt: string;
  updatedAt: string;
  startsAt: string;
  endsAt?: string;
  title: string;
  description: string;
  kidsAge?: number;
  kidsName: string;
  themeId?: string;
}

const PoolRsvpToEventRsvp = [
  ['status', 'Status'],
  ['numKids', 'NumKids'],
  ['kidNames', 'KidNames'],
  ['parentName', 'ParentName'],
  ['parentEmail', 'ParentEmail'],
  ['cardMessage', 'CardMessage'],
];

const poolEventToEventMap = [
  ['key', 'key'],
  ['hostKey', 'hostKey'],
  ['title', 'title'],
  ['kidsName', 'kidsName'],
  ['kidsAge', 'kidsAge'],
  ['description', 'description'],
  ['hostName', 'hostName'],
  ['hostEmail', 'hostEmail'],
  ['startsAt', 'startsAt'],
  ['endsAt', 'endsAt'],
  ['location', 'location'],
  ['themeId', 'themeKey'],
  // ['themeKey', 'themeId'],
  // ['rsvps', 'rsvPs', PoolRsvpToEventRsvp],
];

function mappingReducer(sourceObj: Object, mapping: any[], isMappingModelToApi: boolean): Object {
  /*
    Transforms a sourceObj to a preferred object depending on the mapping array provided.

    sourceObj (obj): Base object class e.g. ApiEvent class
    mapping (array): An array of keys to reference
    isMappingModelToApi (bool): targetObj uses index 0 keys if false
  */

  const sourceMapIndex = isMappingModelToApi ? 0 : 1; // false: sourceMapIndex = 1
  const targetMapIndex = isMappingModelToApi ? 1 : 0; // false: targetMapIndex = 0

  return mapping.reduce((targetObj, mapEl: any[]) => {
    // map rsvps to PoolRsvpToEventRsvp
    // if isMappingModelToApi = true, ergo sourceMapIndex = 0
    // and sourceObj["rsvps"] exist
    if (mapEl.length == 3 && sourceObj[mapEl[sourceMapIndex]]) {
      targetObj[mapEl[targetMapIndex]] = sourceObj[mapEl[sourceMapIndex]].map((o) =>
        mappingReducer(o, mapEl[2], false)
      );
    }
    // else map sourceObj array item[1] to item[0]
    // e.g. { "meetingURL": null } becomes { "zoomLink": null }
    else {
      targetObj[mapEl[targetMapIndex]] = sourceObj[mapEl[sourceMapIndex]];
    }
    return targetObj;
  }, {});
}

// Create PoolEvent object from ApiEvent model
const mapApiToModel = (apiModel: ApiEvent) => mappingReducer(apiModel, poolEventToEventMap, false) as PoolEvent;

// Create ApiEvent object from PoolEvent model
const mapModelToApi = (poolEventModel: PoolEvent) =>
  mappingReducer(poolEventModel, poolEventToEventMap, true) as ApiEvent;

@Injectable({
  providedIn: 'root',
})
export class EventsAPI {
  public key: string; // replaces shortCode
  public hostKey: string;
  public hostName: string; // replaces parentName
  public hostEmail: string; // replaces parentEmail
  public createdAt: string; // ISO 8601 format: 2020-07-10 15:00:00.000
  public updatedAt: string;
  public startsAt: string; // replaces partyDate, startDate, eventDate, startTime
  public endsAt: string; // replaces endDate, endTime
  public title: string; // replaces eventTitle
  public description: string = '';
  public location: string;
  public kidsAge: number; // replaces childAge
  public kidsName: string; // replaces childName
  // removed placeName, placeAddress, placeCity, placeCountry, placePostal, placeProvince
  // removed zoomLink
  // removed moreDetails
  // removed includeCard
  // removed rsvpDate
  private REST_API_SERVER = environment.REST_API_SERVER;
  private CLIENT_ID = environment.CLIENT_ID;
  private apiHeaders = { Client: this.CLIENT_ID, 'Content-Type': 'application/json' };

  constructor(private httpClient: HttpClient, private authAPI: AuthAPI) {}

  resetEvent() {
    this.hostName = undefined;
    this.hostEmail = undefined;
    this.startsAt = undefined;
    this.endsAt = undefined;
    this.title = undefined;
    this.description = undefined;
    this.location = undefined;
    this.kidsAge = undefined;
    this.kidsName = undefined;
  }

  async getEvents(token: string) {
    const headers = { Authorization: `Bearer ${token}` };
    return await this.httpClient
      .get(`${this.REST_API_SERVER}/api/events`, { headers: { ...this.apiHeaders, ...headers } })
      .toPromise();
  }

  getEvent(eventId: string, token: string) {
    const headers = { Authorization: `Bearer ${token}` };
    return this.httpClient
      .get(`${this.REST_API_SERVER}/api/events/${eventId}`, { headers: { ...this.apiHeaders, ...headers } })
      .pipe(map((obj: ApiEvent) => mapApiToModel(obj)));
  }

  getEventByShortCode(key: string) {
    return this.httpClient
      .get(`${this.REST_API_SERVER}/api/events/${key}`, { headers: this.apiHeaders })
      .pipe(map((obj: ApiEvent) => mapApiToModel(obj)));
  }

  async getInvitations(hostKey: string) {
    const token = this.authAPI.acc_token;
    const headers = { Authorization: `Bearer ${token}` };
    return await this.httpClient
      .get(`${this.REST_API_SERVER}/api/events/${hostKey}/invitations`, { headers: { ...this.apiHeaders, ...headers } })
      .toPromise();
  }

  async getGreetings(hostKey: string) {
    const token = await this.authAPI.getAnonToken();
    const headers = { Authorization: `Bearer ${token}` };
    return await this.httpClient
      .get(`${this.REST_API_SERVER}/api/events/${hostKey}/greetings`, { headers: { ...this.apiHeaders, ...headers } })
      .toPromise();
  }

  async getThemes() {
    const token = this.authAPI.anon_token;
    const headers = { Authorization: `Bearer ${token}` };
    return await this.httpClient
      .get(`${this.REST_API_SERVER}/api/themes`, { headers: { ...this.apiHeaders, ...headers } })
      .toPromise();
  }

  async getTheme(id: string) {
    const token = await this.authAPI.getAnonToken();
    const headers = { Authorization: `Bearer ${token}` };
    return await this.httpClient
      .get(`${this.REST_API_SERVER}/api/theme/${id}`, { headers: { ...this.apiHeaders, ...headers } })
      .toPromise();
  }

  createEvent(event: PoolEvent, token: string) {
    const headers = { Authorization: `Bearer ${token}` };
    return this.httpClient
      .post(`${this.REST_API_SERVER}/api/events`, mapModelToApi(event), { headers: { ...this.apiHeaders, ...headers } })
      .pipe(map((obj: ApiEvent) => mapApiToModel(obj)));
  }

  updateEvent(event: any, eventId: string, token: string) {
    const headers = { Authorization: `Bearer ${token}` };
    return this.httpClient
      .patch(`${this.REST_API_SERVER}/api/events/${eventId}`, mapModelToApi(event), {
        headers: { ...this.apiHeaders, ...headers },
      })
      .pipe(map((obj: ApiEvent) => mapApiToModel(obj)));
  }

  createGiftPool(eventKey: string, rq: GiftPoolCreateBody, token: string) {
    const headers = { Authorization: `Bearer ${token}` };
    return this.httpClient
      .post(`${this.REST_API_SERVER}/api/events/${eventKey}/pool`, rq, { headers: { ...this.apiHeaders, ...headers } })
      .pipe(map((obj: GiftPoolEvent) => obj));
  }

  getGiftPool(eventKey: string, token: string) {
    const headers = { Authorization: `Bearer ${token}` };
    return this.httpClient
      .get(`${this.REST_API_SERVER}/api/events/${eventKey}/pool`, { headers: { ...this.apiHeaders, ...headers } })
      .pipe(map((obj: GiftPoolEvent) => obj));
  }

  updateGiftPool(eventKey: string, token: string, rq_body: GiftpoolUpdateBody) {
    const headers = { Authorization: `Bearer ${token}` };
    return this.httpClient
      .patch(`${this.REST_API_SERVER}/api/events/${eventKey}/pool`, rq_body, {
        headers: { ...this.apiHeaders, ...headers },
      })
      .pipe(map((obj: GiftPoolEvent) => obj));
  }

  deleteGiftPool(eventKey: string, token: string) {
    const headers = { Authorization: `Bearer ${token}` };
    return this.httpClient
      .delete(`${this.REST_API_SERVER}/api/events/${eventKey}/pool`, { headers: { ...this.apiHeaders, ...headers } })
      .pipe(map((e) => e));
  }

  getGiftPoolContributions(poolId: string, token: string) {
    const headers = { Authorization: `Bearer ${token}` };
    return this.httpClient
      .get(`${this.REST_API_SERVER}/api/pools/${poolId}/contributions`, { headers: { ...this.apiHeaders, ...headers } })
      .pipe(map((arr: Contributions[]) => arr));
  }

  // async inviteGuest(event: PoolEvent, invitation: InvitationReqBody) {
  //   return await this.internalInviteGuest(event, invitation).toPromise();
  // }

  // private internalInviteGuest(event: PoolEvent, invitation: InvitationReqBody) {
  //   // must provide "eventKey" in the invitation object
  //   return this.httpClient.put(`${this.REST_API_SERVER}/api/invitations`, invitation);
  // }

  // async inviteGuests(event: PoolEvent, invitations: InvitationReqBody[]) {
  //   const invite = (invitation: InvitationReqBody) => this.internalInviteGuest(event, invitation);
  //   return await forkJoin(invitations.map(invite)).toPromise();
  // }

  async rsvp(rsvpData: RSVP) {
    // Guest rsvp to event
    const token = await this.authAPI.getAnonToken();
    const headers = { Authorization: `Bearer ${token}` };
    return await this.httpClient
      .put(`${this.REST_API_SERVER}/api/invitations`, rsvpData, { headers: { ...this.apiHeaders, ...headers } })
      .toPromise();
  }

  async addGreeting(greetings: BirthdayGreetings) {
    const token = await this.authAPI.getAnonToken();
    const headers = { Authorization: `Bearer ${token}` };
    return await this.httpClient
      .post(`${this.REST_API_SERVER}/api/greetings`, greetings, { headers: { ...this.apiHeaders, ...headers } })
      .toPromise();
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
