import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PoolEvent, GiftPoolEvent, Contributions } from 'src/app/models/event';
import { AuthAPI } from 'src/app/providers/authentication.api.service';
import { EventsAPI } from 'src/app/providers/events.api.service';

@Component({
  selector: 'app-giftpool-section',
  templateUrl: './giftpool-section.component.html',
  styleUrls: ['./giftpool-section.component.scss'],
})
export class GiftpoolSectionComponent implements OnInit {
  @Input() eventKey: string = '';
  @Input() hostKey: string = '';
  isLoading: boolean = true;
  contrCount: number = 68;
  contributions: Contributions[];
  shortList: any[];
  showMore: boolean = false;
  private acc_token: string;
  private anon_token: string;
  event: PoolEvent;
  giftpool: GiftPoolEvent;
  total: number;

  constructor(private router: Router, private authAPI: AuthAPI, public eventService: EventsAPI) {}

  async ngOnInit() {
    // this.contributions = [
    //   { from: 'Jenny', amount: 40 },
    //   { from: 'Anonymous', amount: 100 },
    //   { from: 'Kate & Andrew', amount: 200 },
    //   { from: 'Simone, Chloe', amount: 50 },
    //   { from: 'Mark', amount: 500 },
    // ];

    // Get token
    this.acc_token = this.authAPI.acc_token;
    this.anon_token = this.authAPI.anon_token;
    if (!this.anon_token) {
      this.anon_token = await this.authAPI.getAnonToken();
    }

    // Get Event
    const eventResponse = await this.eventService
      .getEvent(this.eventKey, this.acc_token || this.anon_token)
      .toPromise();
    this.event = eventResponse as PoolEvent;
    this.isLoading = false;

    // Check if gift pool exists
    const poolResponse = await this.eventService
      .getGiftPool(this.eventKey, this.acc_token || this.anon_token)
      .toPromise();
    this.giftpool = poolResponse as GiftPoolEvent;
    console.log('---------- gift pool');
    console.log(this.giftpool);

    if (this.giftpool) {
      // Get contributions
      const contributions = await this.eventService
        .getGiftPoolContributions(this.giftpool.id, this.acc_token)
        .toPromise();
      this.contributions = contributions as Contributions[];
      console.log('---------- contributions');
      console.log(this.contributions);
      this.shortList = this.contributions.slice(0, 5); // Shows 1 - 5
      this.showMore = this.shortList.length < this.contributions.length;
      // Sum Total
      this.total = this.contributions.reduce(function (amount, obj) {
        return amount + obj.value;
      }, 0);
    }
  }

  gotoGiftpool() {
    this.router.navigate([`/host/${this.eventKey}/giftpool`]);
  }

  gotoContributions() {
    this.router.navigate([`/host/${this.eventKey}/giftpool/contributions`]);
  }

  editGiftPool() {
    this.router.navigate([`/host/${this.eventKey}/giftpool/edit`]);
  }

  gotoGiftNow() {
    this.router.navigate([`/guest/${this.eventKey}/giftpool/contribute`]);
  }
}
