import { Injectable } from "@angular/core";
import { AuthenticationService } from "./authentication.service";
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
} from "angularfire2/firestore";
import { Observable } from "rxjs";

declare const Stripe;

@Injectable({
  providedIn: "root",
})
export class StripeService {
  stripeCustomerInitialized: boolean;
  sources: any;
  charges: any;
  stripeCheckout: any;

  constructor(private afs: AngularFirestore) {}

  initialize() {
    Stripe.setPublishableKey("pk_test_lqaveG3Z3mfCfOBQIK2GC1V9x");
  }

  processPayment(uid, token, amount) {
    const payment = { source: token, amount };
    return this.afs.collection(`/customers/${uid}/charges`).add(payment);
  }

  setUpDatabase(user) {
    const customerDoc: AngularFirestoreDocument<any> = this.afs.doc(
      `customers/${user.uid}`
    );
    customerDoc.valueChanges().subscribe(
      (value) => {
        if (value && value.data) {
          this.stripeCustomerInitialized = !!value.data.customer_id;
        } else {
          this.stripeCustomerInitialized = false;
        }
      },
      (err) => {
        this.stripeCustomerInitialized = false;
      }
    );

    const customerSourcesDoc: AngularFirestoreCollection<any> = this.afs.collection(
      `customers/${user.uid}/sources`
    );
    customerSourcesDoc.valueChanges().subscribe(
      (value) => {
        this.sources = value;
      },
      (err) => {
        this.sources = {};
      }
    );

    const customerChargesDoc: AngularFirestoreCollection<any> = this.afs.collection(
      `customers/${user.uid}/charges`
    );
    customerChargesDoc.valueChanges().subscribe(
      (value) => {
        this.charges = value;
      },
      (err) => {
        this.charges = {};
      }
    );
  }
}
