import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-support-content',
  templateUrl: './support-content.component.html',
  styleUrls: ['./support-content.component.scss'],
})
export class SupportContentComponent implements OnInit {
  isMobile: boolean = false;

  constructor(private router: Router) {
    const isMobileRegExp = new RegExp('/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i');
    this.isMobile = isMobileRegExp.test(navigator.userAgent);
  }

  ngOnInit() {}
}
