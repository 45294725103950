import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventsAPI } from 'src/app/providers/events.api.service';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { AuthAPI } from 'src/app/providers/authentication.api.service';
import { PoolEvent, GiftPoolEvent } from 'src/app/models/event';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Strings } from 'src/app/shared/strings';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Principal, Account } from 'src/app/models/account';

@Component({
  selector: 'app-host-create-giftpool',
  templateUrl: './host-create-giftpool.component.html',
  styleUrls: ['./host-create-giftpool.component.scss'],
})
export class HostCreateGiftpoolComponent implements OnInit {
  accountType: string;
  private token: string;
  isLoading: boolean = true;
  eventKey: string;
  hostKey: string;
  event: PoolEvent;
  // Form
  createGiftpool: FormGroup;
  public hostEmail: string;
  public expiration: Date;
  public description: string;
  giftpoolExpiry: Date;
  creating: boolean = false;
  // Validation
  validation_messages;
  giftpool: GiftPoolEvent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authAPI: AuthAPI,
    public eventService: EventsAPI,
    private authService: AuthenticationService,
    private _location: Location,
    private modalService: NgbModal
  ) {
    this.createGiftpool = new FormGroup({
      // email: new FormControl(this.hostEmail, {
      //   validators: [Validators.required, Validators.email],
      //   updateOn: 'change',
      // }),
      description: new FormControl(this.description, {
        updateOn: 'change',
      }),
      // expiration: new FormControl(this.giftpoolExpiry, {
      //   validators: Validators.required,
      //   updateOn: 'change',
      // }),
    });

    this.validation_messages = {
      email: [
        { type: 'required', message: Strings.FieldRequired },
        { type: 'email', message: Strings.EmailPattern },
      ],
      expiration: [
        { type: 'required', message: Strings.FieldRequired },
        { type: 'invalid', message: 'Cannot be a past date' },
      ],
    };
  }

  async ngOnInit() {
    // Get token
    this.token = this.authAPI.acc_token;
    if (!this.token) {
      // Redirect to host event page
      this.route.params.subscribe(async (params) => {
        this.router.navigate([`/host/${params['eventId']}`]);
      });
    } else {
      // Get account principal
      const principal = await this.authAPI.getAuthPrincipal(this.token);
      const loadedPrincipal = principal as Principal;
      this.accountType = loadedPrincipal.type;
      this.hostKey = loadedPrincipal.key;

      await this.authService.isAuthenticated();
      this.route.params.subscribe(async (params) => {
        await this.routeLoaded(params);
      });
    }
  }

  async routeLoaded(params) {
    this.eventKey = params['eventId'];
    const eventResponse = await this.eventService.getEvent(this.eventKey, this.token).toPromise();
    this.event = eventResponse as PoolEvent;
    this.isLoading = false;

    console.log(this.event);

    // Get giftpool
    try {
      const poolResponse = await this.eventService.getGiftPool(this.event.key, this.token).toPromise();
      this.giftpool = poolResponse as GiftPoolEvent;
      console.log('---------- gift pool');
      console.log(this.giftpool);
      if ('id' in this.giftpool) {
        // Redirect to edit if giftpool exists
        this.router.navigate([`/host/${this.event.key}/giftpool/edit`]);
      }
    } catch (e) {
      console.log(e);
    }
  }

  invalidDate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return { invalid: { value: control.value } };
    };
  }

  handleDateValidation(unixtimestamp: number) {
    const today = moment().startOf('day').unix();
    // if start time later earlier than today
    if (unixtimestamp >= today) {
      this.createGiftpool.get('expiration').clearValidators();
      this.createGiftpool.get('expiration').updateValueAndValidity();
    }
    // set error
    else {
      this.createGiftpool.get('expiration').setValidators(this.invalidDate());
      this.createGiftpool.get('expiration').updateValueAndValidity();
    }
  }

  // partyDate change
  onDateChange(date: any) {
    // date = MatDatepickerInputEvent object
    // Transform to Intl.DateTimeFormat
    // E.g. Fri Jun 30 2023 00:00:00 GMT-0700 (Pacific Daylight Time)
    const date_obj = new Date(date.value);
    this.giftpoolExpiry = date_obj;
    // this.giftpoolExpiry = date_obj.toISOString(); // "2011-10-05T14:48:00.000Z" Format

    // Get moment objects and unixtimestamps
    const unixtimestamp = date_obj.getTime(); // unixtimestamp (milliseconds)
    this.handleDateValidation(unixtimestamp / 1000);
  }

  back() {
    // this._location.back();
    this.router.navigate([`/host/${this.eventKey}`]);
  }

  gotoGiftpoolConfirm() {
    // if form is invalid,
    // touch all fields to reveal validator error messages
    // if (this.createGiftpool.status === 'INVALID') {
    //   this.createGiftpool.get('email').markAsTouched();
    //   this.createGiftpool.get('expiration').markAsTouched();
    // }
    // // if form is valid,
    // else {
    //   console.log('Form valid');
    // }
    this.creating = true;
    const req_body = {
      isPaused: false,
      country: 'CAN',
      name: this.event.title,
      description: this.description,
    };
    // Then route to next page
    this.eventService.createGiftPool(this.eventKey, req_body, this.token).subscribe((e) => {
      console.log('-------- createGiftPool response');
      console.log(e);
      // progress to next page
      this.router.navigate([`/host/${this.eventKey}/giftpool/confirm`]);
    });
  }

  openModal(content) {
    this.modalService.open(content);
  }
}
