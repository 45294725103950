import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-header-dropdown-menu',
  templateUrl: './header-dropdown-menu.component.html',
  styleUrls: ['./header-dropdown-menu.component.scss'],
  animations: [
    trigger('dropdownOpenClose', [
      state(
        'open',
        style({
          opacity: 1,
          marginTop: 0,
        })
      ),
      state(
        'closed',
        style({
          opacity: 0,
          marginTop: '10px',
        })
      ),
      transition('open => closed', [animate('100ms ease')]),
      transition('closed => open', [animate('100ms ease')]),
    ]),
  ],
})
export class HeaderDropdownMenuComponent implements OnInit {
  isOpen = false;
  @Input() public placement: string = 'bottom-right';

  constructor(private router: Router) {}

  ngOnInit() {}

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  gotoLogin() {
    this.router.navigate(['/login/email']);
  }

  gotoEventsList() {
    this.router.navigate(['/events']);
  }
}
