import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-content',
  templateUrl: './home-content.component.html',
  styleUrls: ['./home-content.component.scss'],
})
export class HomeContentComponent implements OnInit {
  @Output() actionBtn = new EventEmitter<string>();
  public headline: string;
  @Input() public message: string = 'invite';
  @Input() public loginShown: boolean;
  @Input() public account: boolean;
  @Input() public events;
  @Output() updateMessage = new EventEmitter<string>();
  @Output() toggleLogin = new EventEmitter<string>();
  @Output() logout = new EventEmitter<string>();
  @Output() gotoEvents = new EventEmitter<string>();

  constructor(private router: Router) {
    let isMobile = new RegExp('/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i');

    if (isMobile.test(navigator.userAgent)) {
      this.headline = `Invite Your Kiddo’s <br />Best Friends to a Birthday&nbsp;Party`;
    } else {
      this.headline = `Invite Your Kiddo’s Best Friends to a Birthday Party`;
    }
  }

  ngOnInit() {}

  triggerActionBtn() {
    this.actionBtn.emit();
  }

  gotoPage(page: string) {
    this.router.navigate([`/${page}`]);
  }
}
