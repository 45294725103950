import { NgModule, Type } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Routes, RouterModule, Resolve } from '@angular/router';
import { LoginComponent } from './pages/login/login/login.component';
import { LoginEmailComponent } from './pages/login/login-email/login-email.component';
import { LoginCreateComponent } from './pages/login/login-create/login-create.component';
import { LoginPasswordComponent } from './pages/login/login-password/login-password.component';
import { EventListComponent } from './pages/events/event-list/event-list.component';
import { EventCreateComponent } from './pages/events/event-create/event-create.component';
import { EventEditComponent } from './pages/events/event-edit/event-edit.component';
import { HomeComponent } from './pages/home/home.component';
import { GuestEventHomeComponent } from './pages/guest/guest-event-home/guest-event-home.component';
import { HostCreateAdminsComponent } from './pages/host/host-create-admins/host-create-admins.component';
import { HostCreateInviteComponent } from './pages/host/host-create-invite/host-create-invite.component';
import { HostCreatePreviewComponent } from './pages/host/host-create-preview/host-create-preview.component';
import { HostEditEventComponent } from './pages/host/host-edit-event/host-edit-event.component';
import { HostEditGiftpoolComponent } from './pages/host/host-edit-giftpool/host-edit-giftpool.component';
import { HostCreateGiftpoolConfirmComponent } from './pages/host/host-create-giftpool-confirm/host-create-giftpool-confirm.component';
import { HostCreateGiftpoolComponent } from './pages/host/host-create-giftpool/host-create-giftpool.component';
import { HostGiftpoolContributionsComponent } from './pages/host/host-giftpool-contributions/host-giftpool-contributions.component';
import { HostCreateToypoolCheckoutComponent } from './pages/host/host-create-toypool-checkout/host-create-toypool-checkout.component';
import { HostCreateToypoolGoodieBoxesComponent } from './pages/host/host-create-toypool-goodie-boxes/host-create-toypool-goodie-boxes.component';
import { HostCreateToypoolTermsComponent } from './pages/host/host-create-toypool-terms/host-create-toypool-terms.component';
import { HostCreateToypoolComponent } from './pages/host/host-create-toypool/host-create-toypool.component';
import { HostCreate1Component } from './pages/host/host-create-1/host-create-1.component';
import { HostCreate2Component } from './pages/host/host-create-2/host-create-2.component';
import { HostCreate3Component } from './pages/host/host-create-3/host-create-3.component';
import { HostStartComponent } from './pages/host/host-start/host-start.component';
import { GuestEventBirthdayCardEditComponent } from './pages/guest/guest-event-birthday-card-edit/guest-event-birthday-card-edit.component';
import { HostBdayCardSignComponent } from './pages/host/host-bday-card-sign/host-bday-card-sign.component';
import { GuestEventBirthdayCardSignComponent } from './pages/guest/guest-event-birthday-card-sign/guest-event-birthday-card-sign.component';
import { GuestEventBirthdayCardSignedComponent } from './pages/guest/guest-event-birthday-card-signed/guest-event-birthday-card-signed.component';
import { GuestEventBirthdayCardComponent } from './pages/guest/guest-event-birthday-card/guest-event-birthday-card.component';
import { GuestEventToyContributeComponent } from './pages/guest/guest-event-toy-contribute/guest-event-toy-contribute.component';
import { GuestEventToyComponent } from './pages/guest/guest-event-toy/guest-event-toy.component';
import { GuestEventRsvpChangeComponent } from './pages/guest/guest-event-rsvp-change/guest-event-rsvp-change.component';
import { GuestEventRsvpNoConfirmedComponent } from './pages/guest/guest-event-rsvp-no-confirmed/guest-event-rsvp-no-confirmed.component';
import { GuestEventRsvpYesConfirmedComponent } from './pages/guest/guest-event-rsvp-yes-confirmed/guest-event-rsvp-yes-confirmed.component';
import { GuestEventRsvpNoComponent } from './pages/guest/guest-event-rsvp-no/guest-event-rsvp-no.component';
import { GuestEventRsvpYesComponent } from './pages/guest/guest-event-rsvp-yes/guest-event-rsvp-yes.component';
import { GuestGiftpoolContributeComponent } from './pages/guest/guest-giftpool-contribute/guest-giftpool-contribute.component';
import { GuestGiftpoolConfirmedComponent } from './pages/guest/guest-giftpool-confirmed/guest-giftpool-confirmed.component';
import { HostCreateToypoolChooseComponent } from './pages/host/host-create-toypool-choose/host-create-toypool-choose.component';
import { EmailTemplateComponent } from './pages/email/email-template/email-template.component';
import { LoginResolver } from './shared/resolvers/auth.resolve';
import { PrivacyPolicyComponent } from './pages/terms/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './pages/terms/terms-of-use/terms-of-use.component';
import { SupportComponent } from './pages/support/support.component';

const routes: Routes = [
  { path: '', component: HomeComponent, data: { animation: 'HomePage' }, resolve: { user: LoginResolver } },
  { path: 'support', component: SupportComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms', component: TermsOfUseComponent },
  { path: 'login', component: LoginComponent, data: { animation: 'LoginPage' } },
  { path: 'login/email', component: LoginEmailComponent },
  { path: 'login/create', component: LoginCreateComponent },
  { path: 'login/password', component: LoginPasswordComponent },
  { path: 'events', component: EventListComponent, resolve: { user: LoginResolver } },
  { path: 'events/create', component: EventCreateComponent, resolve: { user: LoginResolver } },
  { path: 'events/:eventId/edit', component: EventEditComponent, resolve: { user: LoginResolver } },
  {
    path: 'host/start',
    component: HostStartComponent,
    data: { animation: 'HostStartPage' },
    resolve: { user: LoginResolver },
  },
  {
    path: 'host/create-1',
    component: HostCreate1Component,
    data: { animation: 'HostCreatePage1' },
    resolve: { user: LoginResolver },
  },
  {
    path: 'host/create-2',
    component: HostCreate2Component,
    data: { animation: 'HostCreatePage2' },
    resolve: { user: LoginResolver },
  },
  {
    path: 'host/create-3',
    component: HostCreate3Component,
    data: { animation: 'HostCreatePage3' },
    resolve: { user: LoginResolver },
  },
  {
    path: 'host/:eventId/giftpool',
    component: HostCreateGiftpoolComponent,
    data: { animation: 'HostCreateGiftpoolComponent' },
    resolve: { user: LoginResolver },
  },
  {
    path: 'host/:eventId/giftpool/contributions',
    component: HostGiftpoolContributionsComponent,
    data: { animation: 'HostGiftpoolContributionsComponent' },
    resolve: { user: LoginResolver },
  },
  {
    path: 'host/:eventId/giftpool/confirm',
    component: HostCreateGiftpoolConfirmComponent,
    data: { animation: 'HostCreateGiftpoolConfirmComponent' },
    resolve: { user: LoginResolver },
  },
  {
    path: 'host/:eventId/giftpool/edit',
    component: HostEditGiftpoolComponent,
    data: { animation: 'HostEditGiftpoolComponent' },
    resolve: { user: LoginResolver },
  },
  { path: 'host/:eventId/toypool', component: HostCreateToypoolComponent, resolve: { user: LoginResolver } },
  {
    path: 'host/:eventId/toypool/choose',
    component: HostCreateToypoolChooseComponent,
    resolve: { user: LoginResolver },
  },
  {
    path: 'host/:eventId/toypool/terms',
    component: HostCreateToypoolTermsComponent,
    resolve: { user: LoginResolver },
  },
  {
    path: 'host/:eventId/goodieboxes',
    component: HostCreateToypoolGoodieBoxesComponent,
    resolve: { user: LoginResolver },
  },
  {
    path: 'host/:eventId/checkout',
    component: HostCreateToypoolCheckoutComponent,
    resolve: { user: LoginResolver },
  },
  {
    path: 'host/:eventId/invite',
    component: HostCreateInviteComponent,
    data: { animation: 'HostCreateInvitePage' },
    resolve: { user: LoginResolver },
  },
  {
    path: 'host/:eventId/admins',
    component: HostCreateAdminsComponent,
    data: { animation: 'HostCreateAdminsPage' },
    resolve: { user: LoginResolver },
  },
  { path: 'host/:eventId/emailtemplate', component: EmailTemplateComponent, resolve: { user: LoginResolver } },
  {
    path: 'host/:eventId',
    component: HostCreatePreviewComponent,
    data: { animation: 'HostCreatePreviewComponent' },
    resolve: { user: LoginResolver },
  },
  {
    path: 'host/:eventId/edit',
    component: HostEditEventComponent,
    resolve: { user: LoginResolver },
  },
  {
    path: 'guest/:eventId',
    component: GuestEventHomeComponent,
    data: { animation: 'GuestEventHomeComponent' },
    resolve: { user: LoginResolver },
  },
  {
    path: 'guest/:eventId/rsvp/yes',
    component: GuestEventRsvpYesComponent,
    data: { animation: 'GuestEventRsvpYesComponent' },
    resolve: { user: LoginResolver },
  },
  {
    path: 'guest/:eventId/rsvp/no',
    component: GuestEventRsvpNoComponent,
    data: { animation: 'GuestEventRsvpNoComponent' },
    resolve: { user: LoginResolver },
  },
  {
    path: 'guest/:eventId/rsvp/yesconfirmed',
    component: GuestEventRsvpYesConfirmedComponent,
    resolve: { user: LoginResolver },
  },
  {
    path: 'guest/:eventId/rsvp/noconfirmed',
    component: GuestEventRsvpNoConfirmedComponent,
    resolve: { user: LoginResolver },
  },
  {
    path: 'guest/:eventId/rsvp/change',
    component: GuestEventRsvpChangeComponent,
    resolve: { user: LoginResolver },
  },
  {
    path: 'guest/:eventId/toy',
    component: GuestEventToyComponent,
    resolve: { user: LoginResolver },
  },
  {
    path: 'guest/:eventId/toy/contribute',
    component: GuestEventToyContributeComponent,
    resolve: { user: LoginResolver },
  },
  {
    path: 'guest/:eventId/birthdaycard',
    component: GuestEventBirthdayCardComponent,
    resolve: { user: LoginResolver },
  },
  {
    path: 'guest/:eventId/birthdaycard/signed',
    component: GuestEventBirthdayCardSignedComponent,
    resolve: { user: LoginResolver },
  },
  {
    path: 'host/:eventId/birthdaycard/sign',
    component: HostBdayCardSignComponent,
    data: { animation: 'HostBdayCardSignComponent' },
    resolve: { user: LoginResolver },
  },
  {
    path: 'guest/:eventId/birthdaycard/sign',
    component: GuestEventBirthdayCardSignComponent,
    resolve: { user: LoginResolver },
  },
  {
    path: 'guest/:eventId/birthdaycard/edit',
    component: GuestEventBirthdayCardEditComponent,
    resolve: { user: LoginResolver },
  },
  {
    path: 'guest/:eventId/giftpool/contribute',
    component: GuestGiftpoolContributeComponent,
    data: { animation: 'GuestGiftpoolContributeComponent' },
    resolve: { user: LoginResolver },
  },
  {
    path: 'guest/:eventId/giftpool/thankyou',
    component: GuestGiftpoolConfirmedComponent,
    data: { animation: 'GuestGiftpoolConfirmedComponent' },
    resolve: { user: LoginResolver },
  },
];
@NgModule({
  imports: [BrowserModule, BrowserAnimationsModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
