import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer-nav-alt',
  templateUrl: './footer-nav-alt.component.html',
  styleUrls: ['./footer-nav-alt.component.scss'],
})
export class FooterNavAltComponent implements OnInit {
  @Input() logo: boolean = true;
  @Input() backBtn: boolean = false;
  @Input() manage: boolean = false;
  @Input() type: string = '';
  // Content
  @Input() leftAction: string = '';
  @Input() leftContent: string = '';
  // Functions
  @Output() goBack = new EventEmitter<string>();
  @Output() leftLinkAction = new EventEmitter<string>();
  // Manage Event
  @Input() public numAttending: number = 0;
  @Input() public numNotAttending: number = 0;
  @Input() public showGuestList: boolean = false;
  @Output() editEvent = new EventEmitter<string>();
  @Output() sendInvite = new EventEmitter<string>();
  @Output() seeGuestList = new EventEmitter<string>();

  constructor(private router: Router) {}

  ngOnInit() {}

  gotoPage(page: string) {
    this.router.navigate([`/${page}`]);
  }
}
