import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-secondary-header',
  templateUrl: './secondary-header.component.html',
  styleUrls: ['./secondary-header.component.scss'],
})
export class SecondaryHeaderComponent implements OnInit {
  @Input() headerType: string = 'logo';
  @Input() headerText: string = '';
  @Input() rightLink: string = '';
  @Output() goBack = new EventEmitter<string>();
  @Output() rightLinkAction = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}
}
