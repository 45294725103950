import { Component, ElementRef, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '../../providers/navigation.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit {
  @ViewChild('videoRef', { static: true }) videoRef!: ElementRef;

  public message: string = '';
  public typing: boolean = false;
  public chatMessage: string = '';
  public chatHistory: any[] = [
    {
      response: `🎈 Hello from Teddy Party! I'm here to make organizing your event effortless.<br />
<br />
What can I assist you with today?`,
    },
  ];
  public sessionDate: string = new Date().getTime().toString();
  private store_name: string = 'birthdayteddy.support';

  constructor(private httpClient: HttpClient, private router: Router, private navigation: NavigationService) {
    this.chatMessage = this.chatHistory[this.chatHistory.length - 1].response;
  }

  ngAfterViewInit(): void {
    const media = this.videoRef.nativeElement;
    media.muted = true;
    media.play();
  }

  ngOnInit() {}

  async submitMessage() {
    console.log(this.message);
    const msgToSend = this.message;
    this.typing = true;
    this.message = '';

    // submit this message to api endpoint
    // await this.api.submitMessage(this.message);
    const headers = { 'Content-Type': 'application/json' };
    const response = await this.httpClient
      .post(
        `https://teddygpt.com/api/${this.sessionDate}/teddypartysupport`,
        { message: msgToSend, model: 'davinci' },
        { headers }
      )
      .toPromise();

    console.log(response);
    this.chatHistory.push(response);
    this.chatMessage = this.chatHistory[this.chatHistory.length - 1].response;
    this.typing = false;
    // clear message
    this.message = '';
  }
}
