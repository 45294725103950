import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PoolEvent } from '../../../models/event';
import { AngularFireAuth } from 'angularfire2/auth';
import { Invitation } from '../../../models/invitation';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { EventsAPI } from 'src/app/providers/events.api.service';
import { getDate, getTime } from 'src/app/shared/functions';
import { Location } from '@angular/common';

@Component({
  selector: 'app-guest-event-rsvp-yes',
  templateUrl: './guest-event-rsvp-yes.component.html',
  styleUrls: ['./guest-event-rsvp-yes.component.scss'],
})
export class GuestEventRsvpYesComponent implements OnInit {
  rsvpFormYes;
  validation_messages;
  eventId: string;
  event: PoolEvent;
  eventDate: string;
  startTime: string;
  invitationCode: string;
  invitation: Invitation;
  endTime: string;
  parentEmail: string;
  parentName: string;
  kidNames: string;
  numKids: string;
  cardMessage: string = '';
  kids: string[] = [''];
  password: string = 'passw0rd';

  createItem() {
    return new FormGroup({
      kidName: new FormControl('', {
        validators: Validators.compose([Validators.required, Validators.minLength(2)]),
        updateOn: 'change',
      }),
    });
  }
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private eventService: EventsAPI,
    private afAuth: AngularFireAuth,
    private authService: AuthenticationService,
    private _location: Location
  ) {
    this.rsvpFormYes = new FormGroup({
      parentName: new FormControl('', {
        validators: Validators.compose([Validators.required, Validators.minLength(2)]),
        updateOn: 'change',
      }),
      parentEmail: new FormControl('', {
        validators: Validators.compose([
          Validators.required,
          Validators.pattern(
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i
          ),
        ]),
        updateOn: 'change',
      }),
      kids: new FormArray([this.createItem()]),
    });

    this.validation_messages = {
      parentName: [
        { type: 'required', message: 'This field is required' },
        { type: 'minlength', message: 'This field must have at least 2 characters' },
      ],
      parentEmail: [
        { type: 'required', message: 'This field is required' },
        { type: 'pattern', message: 'Please enter a valid email' },
      ],
      numKids: [
        { type: 'required', message: 'This field is required' },
        { type: 'pattern', message: 'Please enter a number' },
      ],
      kidNames: [
        { type: 'required', message: 'This field is required' },
        { type: 'minlength', message: 'This field must have at least 2 characters' },
      ],
    };
  }

  async ngOnInit() {
    await this.authService.isAuthenticated();
    this.route.params.subscribe(async (params) => {
      this.eventId = params['eventId'];
      const eventResponse = await this.eventService.getEventByShortCode(this.eventId).toPromise();
      const loadedEvent = eventResponse as PoolEvent;
      this.event = loadedEvent;
      this.eventDate = getDate(this.event.startsAt);
      this.startTime = getTime(this.event.startsAt);
      this.endTime = getTime(this.event.endsAt);
      console.log(this.event);
    });
  }

  public addKid() {
    var kidsarray = this.rsvpFormYes.get('kids') as FormArray;
    kidsarray.push(this.createItem());
  }
  public removeKid(index: number) {
    var kidsarray = this.rsvpFormYes.get('kids') as FormArray;
    kidsarray.removeAt(index);
  }

  async rsvp() {
    // debugger;
    if (this.rsvpFormYes.status === 'INVALID') {
      this.rsvpFormYes.get('parentName').markAsTouched();
      this.rsvpFormYes.get('parentEmail').markAsTouched();
      this.rsvpFormYes.get('numKids').markAsTouched();
    } else {
      const kids = this.rsvpFormYes.get('kids') as FormArray;
      var kidNameStrings = kids.controls.map((c) => c.value.kidName);
      console.log(this.numKids);
      if (this.parentName && this.parentEmail && kidNameStrings) {
        sessionStorage.setItem('teddy.userEmail', this.parentEmail);
        await this.eventService.rsvp({
          eventKey: this.event.key,
          inviteeEmail: this.parentEmail,
          inviteeName: this.parentName,
          guests: kidNameStrings,
          rsvp: 'attending',
          // cardMessage: this.cardMessage
        });
        this.router.navigate([`/guest/${this.eventId}/rsvp/yesconfirmed`]);
      }
    }
  }

  back() {
    this._location.back();
  }
}
