import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventsAPI } from 'src/app/providers/events.api.service';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { AuthAPI } from 'src/app/providers/authentication.api.service';
import { PoolEvent } from 'src/app/models/event';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-host-create-giftpool-confirm',
  templateUrl: './host-create-giftpool-confirm.component.html',
  styleUrls: ['./host-create-giftpool-confirm.component.scss'],
})
export class HostCreateGiftpoolConfirmComponent implements OnInit {
  private token: string;
  isLoading: boolean = true;
  hostKey: string;
  event: PoolEvent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authAPI: AuthAPI,
    public eventService: EventsAPI,
    private authService: AuthenticationService,
    private modalService: NgbModal
  ) {}

  async ngOnInit() {
    // Get token
    this.token = this.authAPI.acc_token;
    if (!this.token) {
      // Redirect to host event page
      this.route.params.subscribe(async (params) => {
        this.router.navigate([`/host/${params['eventId']}`]);
      });
    } else {
      await this.authService.isAuthenticated();
      this.route.params.subscribe(async (params) => {
        await this.routeLoaded(params);
      });
    }
  }

  async routeLoaded(params) {
    this.hostKey = params['eventId'];
    const eventResponse = await this.eventService.getEvent(this.hostKey, this.token).toPromise();
    this.event = eventResponse as PoolEvent;
    this.isLoading = false;

    console.log(this.event);
  }

  gotoEventPage() {
    this.router.navigate([`/host/${this.event.key}`]);
  }

  gotoEditGiftpool() {
    this.router.navigate([`/host/${this.event.key}/giftpool/edit`]);
  }

  openModal(content) {
    this.modalService.open(content);
  }
}
