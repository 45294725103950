import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/providers/navigation.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthAPI } from 'src/app/providers/authentication.api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm;
  validation_messages;
  public email: string;
  public sending: boolean = false;
  public emailSent: boolean = false;
  @Output() hideLogin = new EventEmitter<string>();
  public emailError: boolean = false;

  constructor(private authAPI: AuthAPI, private router: Router, private navigation: NavigationService) {
    this.loginForm = new FormGroup({
      email: new FormControl(this.email, {
        validators: Validators.compose([Validators.required]),
        updateOn: 'change',
      }),
    });

    this.validation_messages = {
      email: [{ type: 'required', message: 'This field is required' }],
    };
  }

  ngOnInit() {}

  sendEmail() {
    this.sending = true;
    this.emailError = false;
    // if form is invalid,
    // touch all fields to reveal validator error messages
    if (this.loginForm.status === 'INVALID') {
      this.loginForm.get('email').markAsTouched();
    }
    // if form is valid,
    // create event
    else {
      this.authAPI.sendAuthEmail(this.email).subscribe(
        (e) => {
          console.log('-------- sendAuthEmail response');
          console.log(e);
          this.emailSent = true;
          this.sending = false;
        },
        (e) => {
          this.emailError = true;
          this.sending = false;
        }
      );
    }
  }

  back() {
    // this.navigation.back();
    this.hideLogin.emit();
  }
}
