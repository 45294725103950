import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-bday-video',
  templateUrl: './bday-video.component.html',
  styleUrls: ['./bday-video.component.scss'],
})
export class BdayVideoComponent implements OnInit {
  @Input() showing: string = 'clean_001';
  @Input() customClasses: string = '';

  constructor() {}

  ngOnInit() {}
}
