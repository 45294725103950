import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthAPI } from 'src/app/providers/authentication.api.service';
import { EventsAPI } from 'src/app/providers/events.api.service';
@Component({
  selector: 'app-host-create-toypool-terms',
  templateUrl: './host-create-toypool-terms.component.html',
  styleUrls: ['./host-create-toypool-terms.component.scss'],
})
export class HostCreateToypoolTermsComponent implements OnInit {
  eventId: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authAPI: AuthAPI,
    private eventService: EventsAPI
  ) {}

  async ngOnInit() {
    const token = await this.authAPI.getAnonToken();
    this.route.params.subscribe(async (params) => {
      this.eventId = params['eventId'];
      const eventResponse = await this.eventService.getEvent(this.eventId, token).toPromise();
      const loadedEvent = (eventResponse as any).event;
    });
  }

  gotoToypoolGoodieBoxes() {
    this.router.navigate(['/host/create/goodieboxes']);
  }

  gotoToypoolChoose() {
    this.router.navigate(['/host/create/toypool/choose']);
  }
}
