import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { EventsAPI } from 'src/app/providers/events.api.service';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { AuthAPI } from 'src/app/providers/authentication.api.service';
import { PoolEvent, GiftPoolEvent, Contributions } from 'src/app/models/event';

@Component({
  selector: 'app-host-giftpool-contributions',
  templateUrl: './host-giftpool-contributions.component.html',
  styleUrls: ['./host-giftpool-contributions.component.scss'],
})
export class HostGiftpoolContributionsComponent implements OnInit {
  private token: string;
  isLoading: boolean = true;
  hostKey: string;
  event: PoolEvent;
  giftpool: GiftPoolEvent;
  contrCount: number = 68;
  contributions: Contributions[];
  total: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authAPI: AuthAPI,
    public eventService: EventsAPI,
    private authService: AuthenticationService,
    private _location: Location
  ) {}

  async ngOnInit() {
    // Get token
    this.token = this.authAPI.acc_token;
    if (!this.token) {
      this.token = await this.authAPI.getAnonToken();
    }

    await this.authService.isAuthenticated();
    this.route.params.subscribe(async (params) => {
      await this.routeLoaded(params);
    });
  }

  async routeLoaded(params) {
    this.hostKey = params['eventId'];
    const eventResponse = await this.eventService.getEvent(this.hostKey, this.token).toPromise();
    this.event = eventResponse as PoolEvent;

    // Get giftpool
    const poolResponse = await this.eventService.getGiftPool(this.event.key, this.token).toPromise();
    this.giftpool = poolResponse as GiftPoolEvent;
    console.log('---------- gift pool');
    console.log(this.giftpool);

    // Check if contributions exist
    if (this.giftpool) {
      // Get contributions
      const contributions = await this.eventService.getGiftPoolContributions(this.giftpool.id, this.token).toPromise();
      this.contributions = contributions as Contributions[];
      console.log('---------- contributions');
      console.log(this.contributions);
    } else {
      // Redirect if not found
      this.router.navigate([`/host/${this.event.key}/giftpool`]);
    }

    // Sum Total
    this.total = this.contributions.reduce(function (amount, obj) {
      return amount + obj.value;
    }, 0);

    this.isLoading = false;
  }

  editGiftPool() {
    this.router.navigate([`/host/${this.event.key}/giftpool/edit`]);
  }

  back() {
    this.router.navigate([`/host/${this.event.key}`]);
    // this._location.back();
  }
}
