import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-giftpool-faq',
  templateUrl: './giftpool-faq.component.html',
  styleUrls: ['./giftpool-faq.component.scss'],
})
export class GiftpoolFaqComponent implements OnInit {
  @Output() closeModal = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}
}
