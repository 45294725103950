import { Component, NgZone, ViewChild, OnInit } from '@angular/core';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Router } from '@angular/router';
import { PoolEvent } from '../../../models/event';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AuthenticationService } from '../../../providers/authentication.service';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { EventsAPI } from 'src/app/providers/events.api.service';
import { Strings } from 'src/app/shared/strings';
import { NavigationService } from '../../../providers/navigation.service';
import { getTime, formatHours, getAMPM, getTimePartials } from 'src/app/shared/functions';

@Component({
  selector: 'app-host-create-2',
  templateUrl: './host-create-2.component.html',
  styleUrls: ['./host-create-2.component.scss'],
})
export class HostCreate2Component implements OnInit {
  createInputPage2;
  validation_messages;
  public partyDate: Date;
  public startTime: string; // 7:00 PM format, not used in Event model
  public endTime: string; // 7:00 PM format, not used in Event model

  constructor(
    private router: Router,
    public eventsService: EventsAPI,
    private _ngZone: NgZone,
    private authService: AuthenticationService,
    private navigation: NavigationService
  ) {
    this.createInputPage2 = new FormGroup({
      title: new FormControl(eventsService.title, {
        validators: Validators.required,
        updateOn: 'change',
      }),
      partyDate: new FormControl(this.partyDate, {
        validators: Validators.required,
        updateOn: 'change',
      }),
      startTime: new FormControl(this.startTime, {
        validators: Validators.required,
        updateOn: 'change',
      }),
      endTime: new FormControl(this.endTime),
      description: new FormControl(eventsService.description),
      partyLocation: new FormControl(eventsService.location, {
        validators: Validators.required,
        updateOn: 'change',
      }),
    });

    this.validation_messages = {
      title: [{ type: 'required', message: Strings.FieldRequired }],
      partyDate: [
        { type: 'required', message: Strings.FieldRequired },
        { type: 'invalid', message: 'Event cannot be in the past' },
      ],
      startTime: [
        { type: 'required', message: Strings.FieldRequired },
        { type: 'invalid', message: 'Event cannot be in the past' },
      ],
      endTime: [{ type: 'required', message: Strings.FieldRequired }],
      description: [{ type: 'required', message: Strings.FieldRequired }],
      partyLocation: [{ type: 'required', message: Strings.FieldRequired }],
      invalidTimes: { message: 'Invalid' },
    };
  }

  @ViewChild('autosize', { static: false }) autosize: CdkTextareaAutosize;

  invalidEventStart(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return { invalid: { value: control.value } };
    };
  }

  handleDateValidation() {
    const now = moment().unix();
    const starts_at = Date.parse(this.eventsService.startsAt) / 1000;

    console.log(starts_at < now);
    console.log(now);
    console.log(starts_at);

    // if start time is earlier than end time
    if (starts_at > now) {
      this.createInputPage2.get('partyDate').clearValidators();
      this.createInputPage2.get('partyDate').updateValueAndValidity();
      this.createInputPage2.get('startTime').clearValidators();
      this.createInputPage2.get('startTime').updateValueAndValidity();
    }
    // set error
    else {
      this.createInputPage2.get('partyDate').setValidators(this.invalidEventStart());
      this.createInputPage2.get('partyDate').updateValueAndValidity();
      this.createInputPage2.get('partyDate').markAsTouched();
      this.createInputPage2.get('startTime').setValidators(this.invalidEventStart());
      this.createInputPage2.get('startTime').updateValueAndValidity();
      this.createInputPage2.get('startTime').markAsTouched();
    }
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  async ngOnInit() {
    await this.authService.isAuthenticated();

    // Set partyDate, startsAt, startTime and endTime
    if (this.eventsService.startsAt) {
      this.partyDate = new Date(this.eventsService.startsAt);
      this.startTime = getTime(this.eventsService.startsAt);
    } else {
      const party_date_unix = moment().add(1, 'day').startOf('hour').unix();
      this.partyDate = new Date(party_date_unix * 1000);
      this.eventsService.startsAt = this.partyDate.toISOString();
      const hours = this.partyDate.getHours();
      this.startTime = `${formatHours(hours)}:00 ${getAMPM(hours)}`;

      console.log(this.eventsService.startsAt);
      console.log(this.startTime);
    }

    if (this.eventsService.endsAt) {
      this.endTime = getTime(this.eventsService.endsAt);
    }
  }

  handleTimes() {
    // Update eventsService.startsAt
    const [s_hours, s_mins, s_ampm] = getTimePartials(this.startTime);
    let new_start_date = new Date(this.eventsService.startsAt);
    new_start_date.setHours(Number(s_hours), Number(s_mins));
    this.eventsService.startsAt = new_start_date.toISOString();
    console.log('this.eventsService.startsAt: ' + this.eventsService.startsAt);

    // Update eventsService.endsAt
    if (this.endTime) {
      let new_end_date = new Date(this.eventsService.startsAt);
      const [e_hours, e_mins, e_ampm] = getTimePartials(this.endTime);

      // endsAt is next day if "endTime" is earlier than or same as "startTime"
      if (e_hours <= s_hours) {
        new_end_date.setDate(new_end_date.getDate() + 1);
      }

      new_end_date.setHours(Number(e_hours), Number(e_mins));
      this.eventsService.endsAt = new_end_date.toISOString();
      console.log('this.eventsService.endsAt = ' + this.eventsService.endsAt);
    }
  }

  // partyDate change
  onDateChange(date: any) {
    // date = MatDatepickerInputEvent object
    // Transform to Intl.DateTimeFormat
    // E.g. Fri Jun 30 2023 00:00:00 GMT-0700 (Pacific Daylight Time)
    const date_obj = new Date(date.value);

    // Add startTime hours & mins to date
    const [s_hours, s_mins, s_ampm] = getTimePartials(this.startTime);
    date_obj.setHours(Number(s_hours), Number(s_mins));

    // Handle party date
    this.partyDate = date_obj;
    this.eventsService.startsAt = date_obj.toISOString(); // "2011-10-05T14:48:00.000Z" Format

    // Handle party times
    this.handleTimes();

    // Validate Date
    this.handleDateValidation();
  }

  // startTime change
  startTimeChanged(time: any) {
    // time = 7:00 PM format
    this.startTime = time;
    this.handleTimes();
    this.handleDateValidation();
  }

  // endTime change
  endTimeChanged(time: any) {
    // time = 7:00 PM format
    this.endTime = time;
    this.handleTimes();
  }

  public handleLocationChange(address) {
    console.log(address);
    // Delete address content if the input field is empty
    if (address.name === '') {
      this.eventsService.location = '';
      this.createInputPage2.get('partyLocation').updateValueAndValidity();
    } else {
      this.eventsService.location = address.formatted_address;
    }
  }

  // partyLocation change
  // public handleAddressChange(address) {
  //   console.log(address);
  //   // Delete address content if the input field is empty
  //   if (address.name === '') {
  //     this.eventsService.placeAddress = '';
  //     this.eventsService.placeName = '';
  //     this.eventsService.placeCity = '';
  //     this.eventsService.placePostal = '';
  //     this.eventsService.placeCountry = '';
  //     this.eventsService.placeProvince = '';
  //     this.createInputPage2.get('partyLocation').updateValueAndValidity();
  //   }
  //   // Otherwise update the location address fields as follows
  //   else {
  //     const streetNumber = (this.eventsService.placeAddress = address.address_components.find((c) =>
  //       c.types.includes('street_number')
  //     ).long_name);
  //     const streetName = (this.eventsService.placeAddress = address.address_components.find((c) =>
  //       c.types.includes('route')
  //     ).long_name);
  //     const cityName = address.address_components.find((c) => c.types.includes('locality')).long_name;
  //     const postalCode = address.address_components.find((c) => c.types.includes('postal_code')).long_name;
  //     const countryName = address.address_components.find((c) => c.types.includes('country')).long_name;
  //     const provinceName = address.address_components.find((c) =>
  //       c.types.includes('administrative_area_level_1')
  //     ).short_name;
  //     // debugger;
  //     this.eventsService.placeAddress = streetNumber + ' ' + streetName;
  //     this.eventsService.placeName = address.formatted_address;
  //     this.eventsService.placeCity = cityName;
  //     this.eventsService.placePostal = postalCode;
  //     this.eventsService.placeCountry = countryName;
  //     this.eventsService.placeProvince = provinceName;
  //   }
  // }

  public updateAddress(event) {
    if (event.target.value === '') {
      this.handleLocationChange({ name: '' });
    }
  }

  // zoomLink change
  // public zoomLinkChange(event) {
  //   // if zoomLink field has value
  //   // remove validator from partyLocation
  //   if (event.target.value !== '') {
  //     this.createInputPage2.get('partyLocation').clearValidators();
  //     this.createInputPage2.get('partyLocation').updateValueAndValidity();
  //   }
  //   // if zoomLink input is empty
  //   // add validator back to partyLocation
  //   else {
  //     this.createInputPage2.get('partyLocation').setValidators(this.mustHaveOne());
  //     this.createInputPage2.get('partyLocation').updateValueAndValidity();
  //   }
  // }

  gotoCreate3() {
    // if form is invalid,
    // touch all fields to reveal validator error messages
    if (this.createInputPage2.status === 'INVALID') {
      this.createInputPage2.get('title').markAsTouched();
      this.createInputPage2.get('partyDate').markAsTouched();
      this.createInputPage2.get('startTime').markAsTouched();
      this.createInputPage2.get('endTime').markAsTouched();
      this.createInputPage2.get('description').markAsTouched();
      this.createInputPage2.get('partyLocation').markAsTouched();
    }
    // if form is valid,
    // progress to next page
    else {
      console.log(this.eventsService);
      this.router.navigate([`/host/create-3`]);
    }
  }

  back() {
    this.navigation.back();
  }

  goHome() {
    this.eventsService.resetEvent();
    this.router.navigate(['/']);
  }
}
