import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-what-is-toypool-guest',
  templateUrl: './what-is-toypool-guest.component.html',
  styleUrls: ['./what-is-toypool-guest.component.scss']
})
export class WhatIsToypoolGuestComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
