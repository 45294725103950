import { trigger, style, animate, animateChild, query, group, transition } from '@angular/animations';

export const slideInAnimation = trigger('routeAnimations', [
  // Forwards
  transition(
    'HomePage => HostCreatePage1, HomePage => LoginPage, HostStartPage => HostCreatePage1, HostCreatePage1 => HostCreatePage2, HostCreatePage2 => HostCreatePage3, HostCreatePage3 => HostCreatePreviewComponent, HostCreatePreviewComponent => HostCreateAdminsPage, HostCreatePreviewComponent => HostCreateInvitePage, GuestEventHomeComponent => GuestEventRsvpYesComponent, GuestEventRsvpYesComponent => GuestEventRsvpYesConfirmedComponent, GuestEventHomeComponent => GuestEventRsvpNoComponent, GuestEventRsvpNoComponent => GuestEventRsvpNoConfirmedComponent, GuestEventHomeComponent => GuestGiftpoolContributeComponent, HostCreatePreviewComponent => HostCreateGiftpoolComponent, HostCreatePreviewComponent => HostBdayCardSignComponent, HostCreateGiftpoolComponent => HostCreateGiftpoolConfirmComponent, HostCreatePreviewComponent => HostEditGiftpoolComponent, HostCreateGiftpoolConfirmComponent => HostEditGiftpoolComponent, HostCreatePreviewComponent => HostGiftpoolContributionsComponent, HostEditGiftpoolComponent => HostGiftpoolContributionsComponent',
    [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        }),
      ]),
      query(':enter', [style({ left: '100%' })]),
      query(':leave', animateChild()),
      group([
        query(':leave', [animate('200ms ease', style({ left: '-100%' }))]),
        query(':enter', [animate('200ms ease', style({ left: '0%' }))]),
      ]),
      query(':enter', animateChild()),
    ]
  ),
  // Back
  transition(
    'HostCreatePage1 => HomePage, LoginPage => HomePage, HostCreatePage1 => HostStartPage, HostCreatePage2 => HostCreatePage1, HostCreatePage3 => HostCreatePage2, HostCreatePreviewComponent => HostCreatePage3, HostCreateAdminsPage => HostCreatePreviewComponent, HostCreateInvitePage => HostCreatePreviewComponent, GuestEventRsvpYesComponent => GuestEventHomeComponent, GuestEventRsvpNoComponent => GuestEventHomeComponent, GuestGiftpoolContributeComponent => GuestEventHomeComponent, HostCreateGiftpoolComponent => HostCreatePreviewComponent, HostBdayCardSignComponent => HostCreatePreviewComponent, HostCreateGiftpoolConfirmComponent => HostCreateGiftpoolComponent, HostEditGiftpoolComponent => HostCreatePreviewComponent, HostEditGiftpoolComponent => HostCreateGiftpoolConfirmComponent, HostGiftpoolContributionsComponent => HostCreatePreviewComponent',
    [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        }),
      ]),
      query(':enter', [style({ left: '-100%' })]),
      query(':leave', animateChild()),
      group([
        query(':leave', [animate('200ms ease-out', style({ left: '100%' }))]),
        query(':enter', [animate('200ms ease-out', style({ left: '0%' }))]),
      ]),
      query(':enter', animateChild()),
    ]
  ),
]);
