import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer-nav',
  templateUrl: './footer-nav.component.html',
  styleUrls: ['./footer-nav.component.scss'],
})
export class FooterNavComponent implements OnInit {
  @Input() logo: boolean = true;
  @Input() backBtn: boolean = false;
  @Input() manage: boolean = false;
  @Input() type: string = '';
  // Content
  @Input() leftContent: string = '';
  @Input() rightLink: string = '';
  // Functions
  @Output() goBack = new EventEmitter<string>();
  @Output() leftLinkAction = new EventEmitter<string>();
  @Output() rightLinkAction = new EventEmitter<string>();

  constructor(private router: Router) {}

  ngOnInit() {}

  // logoClick() {
  //   console.log('logoClick');
  //   this.router.navigate([`/`]);
  // }

  gotoPage(page: string) {
    this.router.navigate([`/${page}`]);
  }
}
