import { User } from 'firebase';
import { EventLocation } from './event-location';
import { Invitation } from './invitation';

export class PoolEventRsvp {
  status: number;
  cardMessage: string = '';
}
export class PoolEvent {
  key: string;
  hostKey: string;
  kidsName: string;
  kidsAge?: number;
  title: string;
  location: string;
  // address: string;
  // extra: string;
  // city: string;
  // country: string;
  // postal: string;
  // zoomLink: string;
  description: string;
  rsvpDate: number;
  hostName: string;
  hostEmail: string;
  requireRsvp: boolean;
  toyPool: boolean;
  invitations: Invitation[];
  includeCard: boolean = false;
  product: number;
  shippingAddress: string;
  shippingCity: string;
  shippingCountry: string;
  shippingPostal: string;
  startsAt: string;
  endsAt?: string;
  creator: User;
  // shortCode: string;
  // rsvps: PoolEventRsvp[];
  createdAt: string;
  updatedAt: string;
  themeId?: string;
  constructor() {}
}

export class GiftPoolEvent {
  readonly id: string; // An unique identifier that represents the event across the whole system
  readonly name: string; // The event name choosen by the user/client
  readonly description?: string; // The event description choosen by the user/client
  readonly createdAt: Date; // The ISO 8601 timestamp indicating when the event entity was created
  readonly updatedAt: Date; // The ISO 8601 timestamp indicating the last time the event entity was updated
  readonly isPaused: boolean; // Weather the event is currently paused by the host or not
  readonly eventKey: string; // The key of the event the pool is attatched to
}

export class GiftPoolCreateBody {
  readonly isPaused?: boolean;
  readonly country: string;
  readonly name: string;
  readonly description?: string;
}

export class GiftpoolUpdateBody {
  isPaused?: boolean;
  name?: string;
  description?: string;
}

export class Contributions {
  readonly id: string; // An unique id that represents the contribution across the whole system
  readonly createdAt: string; // The ISO 8601 timestamp indicating when the contribution entity was created
  readonly updatedAt: string; // The ISO 8601 timestamp indicating the last time the contribution entity was updated
  readonly poolId: string; // The unique identifier that points to the pool the contribution is being done for
  readonly value: number; // The financial value in cents desidnated to the payment
  readonly paymentId: string; // The unique identifier that points to the payment entity related to the contribution
  readonly contributorKey: string; // The unique identifier that points to the user making the contribution
  readonly contributorName: string; // The name provided by the user while making the contribution
  readonly contributorEmail: string; // The email provided by the user while making the contribution
}

export class CreateContributionBody {
  readonly value: number; // The financtial value in cents to be charged as a contribution
  readonly contributorName: string; // The contributor's name
  readonly contributorEmail: string; // The email adddress for the contributor
}
