import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { Location } from '@angular/common';
import { PoolEvent } from 'src/app/models/event';
import { EventsAPI } from 'src/app/providers/events.api.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Strings } from 'src/app/shared/strings';

@Component({
  selector: 'app-birthday-greetings-sign',
  templateUrl: './birthday-greetings-sign.component.html',
  styleUrls: ['./birthday-greetings-sign.component.scss'],
})
export class BirthdayGreetingsSignComponent implements OnInit {
  @Input() eventId: string;
  @Input() hostKey: string = '';
  event: PoolEvent;
  childName: string = '';
  btnText: string = 'Add Message';
  signed: boolean = false;
  birthdayGreeting: string = '';
  from: string = '';
  greetingForm = new FormGroup({
    birthdayGreeting: new FormControl(this.birthdayGreeting, {
      validators: Validators.required,
      updateOn: 'change',
    }),
    from: new FormControl(this.from, {
      validators: Validators.required,
      updateOn: 'change',
    }),
  });
  validation_messages = {
    birthdayGreeting: [{ type: 'required', message: Strings.FieldRequired }],
    from: [{ type: 'required', message: Strings.FieldRequired }],
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private _location: Location,
    private eventService: EventsAPI
  ) {}

  async ngOnInit() {
    await this.authService.isAuthenticated();

    this.route.params.subscribe(async (params) => {
      const eventResponse = await this.eventService.getEventByShortCode(this.eventId).toPromise();
      const loadedEvent = eventResponse as PoolEvent;
      this.event = loadedEvent;
      this.childName = loadedEvent.kidsName;
    });

    // TODO: Check to see if card has been signed
    // Update component state if user has previously signed the card
    if (this.signed) {
      this.birthdayGreeting = "User's message";
      this.from = 'Aunt Marge';
      this.btnText = 'Edit Message';
    }
  }

  async gotoBirthdayCardSigned() {
    if (this.greetingForm.status === 'INVALID') {
      this.greetingForm.get('birthdayGreeting').markAsTouched();
      this.greetingForm.get('from').markAsTouched();
    } else {
      const current_time = new Date().toISOString();
      const greeting_obj = {
        eventKey: this.event.key,
        createdAt: current_time,
        updatedAt: current_time,
        signer: this.from,
        message: this.birthdayGreeting,
      };

      await this.eventService.addGreeting(greeting_obj);
      console.log('---------- sign card');
      console.log('eventKey = ' + this.event.key);
      console.log(greeting_obj);

      if (this.hostKey) {
        // Go to preview
        this.router.navigate([`/host/${this.hostKey}`]);
      } else {
        // Go to Signed Card
        this.router.navigate([`/guest/${this.eventId}/birthdaycard/signed`]);
      }
    }
  }

  back() {
    this._location.back();
  }
}
