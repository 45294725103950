import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PoolEvent } from 'src/app/models/event';
import { EventsAPI } from 'src/app/providers/events.api.service';
import { BirthdayGreetings } from 'src/app/models/birthday-greetings';

@Component({
  selector: 'app-birthday-greetings',
  templateUrl: './birthday-greetings.component.html',
  styleUrls: ['./birthday-greetings.component.scss'],
})
export class BirthdayGreetingsComponent implements OnInit {
  public greetings: BirthdayGreetings[] = [];
  public btnText: string = 'Sign the Card';
  @Input() childName: string = '';
  @Input() eventId: string;
  @Input() location: string;
  public signed: boolean = false;
  public notCardPage: boolean = true;

  constructor(private router: Router, private eventService: EventsAPI) {}

  async ngOnInit() {
    if (this.eventId) {
      const data = await this.eventService.getGreetings(this.eventId);
      console.log('---------- greetings');
      console.log('eventId = ' + this.eventId);
      console.log(data);
      if (Array.isArray(data) && data.length > 0) {
        this.greetings = data;
      }
    }

    // Check if user has signed the card
    // If card has been signed, show edit button instead
    if (this.signed) {
      this.btnText = 'Edit Message';
    }

    if (window.location.href.includes('/birthdaycard')) {
      this.notCardPage = false;
    }

    // Observe window size
    const rightPanel = document.getElementById('party-content-area');
    if (rightPanel) {
      const rightPanelWidth = rightPanel.offsetWidth;

      if (rightPanelWidth > 575) {
        const cardCover = document.getElementById('bday-card-cover');
        const contentPanel = document.getElementById('bday-card-content');
        const rightWidth = cardCover.offsetWidth > rightPanelWidth / 2 ? rightPanelWidth / 2 : cardCover.offsetWidth;
        contentPanel.setAttribute('style', `width: ${rightWidth}px; height: ${cardCover.offsetHeight}px`);
      }
    }
  }

  goToBirthdayCard() {
    this.router.navigate([`/guest/${this.eventId}/birthdaycard/sign`]);
  }
}
